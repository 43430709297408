import axios from "axios"
import {Storage} from "@/services/Storage"
import router from "@/router";

export default {
    name: 'payment',
    state: {

    },
    getters: {

    },

    mutations: {

    },
    actions: {
        getStoreByID: (context,id) => {
            return axios.get(franchiseTomcatApi+`kazangSignUp/getStoreByID`,{
                params : { id : id }
            }).then(({data})=>data)
        },

        submitData(context,payload){
            return axios.post(`https://chrilantech.com/LytSoftPosOnline/api/email/sendKazangSignUp`,payload,{
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(()=>responses.showSuccess(`Request Submitted Successfully`))
        },

        checkSmartInvoicing: async (context) => {
            let storeDB = await Storage.getCurrentStoreDB();
            if (!storeDB) return;
            let user = await Storage.getCurrentUser();
            return axios.get(franchiseTomcatApi + `GRV/${storeDB}/checkSmartInvoicing`)
                .then(({data}) => {
                    context.commit(`setSmartInvoicingStatus`, data)
                    let isLive = context.getters.getSmartInvoicingLive;
                    if (!data && isLive) {
                        return context.dispatch(`checkIfStoreDocsSubmitted`).then(() => {
                            let hasDocs = context.getters.getDocSubmissionStatus;

                            if (!hasDocs && !!user) {
                                return Swal.fire({
                                    title: 'Register for Smart Invoicing Now',
                                    text: 'You are not registered for smart invoicing. Please register now.',
                                    icon: 'warning',
                                    showCancelButton: true,
                                    showCloseButton : true,
                                    allowOutsideClick : false,
                                    confirmButtonText: 'Register',
                                    cancelButtonText: 'Cancel'
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        return router.push({name: 'smart-invoicing-setup'});
                                    }
                                });
                            }
                        })
                    }
                }).catch((err) => errorHandler.tomcatError(err))
        },
    }
}
