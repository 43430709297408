<template>
  <full-page title="Create Ticket">
    <div class="card m-3 p-2">
      <form @submit.prevent="SendTicket">
        <div class="row mb-3">
          <div class="col-md-12">
            <!-- Type ticket dropdown -->
            <div class="form-group">
              <label for="type">Type: Select whether it's a ticket or billing issue...</label>
              <select class="form-control" id="type" v-model="data.type">
                <option value="error">error</option>
                <option value="featureRequest">feature Request</option>
                <option value="billing">Billing</option>
                <option value="consultation">consultation</option>
                <option value="calloutRequest">Call out request</option>
              </select>
            </div>

            <!-- Platform type dropdown -->
            <div class="form-group">
              <label for="platform" >Platform: The LytSoft platform affected.</label>
              <input type="text" id="platform" readonly v-model="data.platform" class="form-control">
            </div>

            <!-- Title field -->
            <div class="form-group">
              <label for="title">Title</label>
              <input type="text" id="title" v-model="data.title" placeholder="Brief Description Of Issue" class="form-control" required>
            </div>

            <!-- Description textarea -->
            <div class="form-group">
              <label for="description">Description: Please give a detailed explanation of the request</label>
              <vue-editor id="description" v-model="data.description" :editor-toolbar="toolbar"></vue-editor>
            </div>

            <!-- Toggle all stores -->
            <div class="form-group form-check">
              <input type="checkbox" class="form-check-input" id="allStores" v-model="data.allStores">
              <label class="form-check-label" for="allStores">Apply to all stores</label>
            </div>
          </div>
        </div>
        <button type="submit" class="btn btn-primary w-100">Send Ticket</button>
      </form>
    </div>
  </full-page>
</template>

<script>
import FullPage from "@/views/point-of-sale/tables/FullPage";
import { Storage } from "@/services/Storage";
import axios from "axios";

export default {
  name: "Ticket-Creation",
  components: { FullPage },
  beforeMount() {
    this.getTicketById();
  },
  data() {
    return {
      toolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        ["code-block"]
      ],
      id : this.$route.query.id,
      data: {
        type: "",
        title: "",
        platform: "KazangPOS",
        description: "",
        allStores: false,
      }
    };
  },
  methods: {
    async SendTicket() {
      let DB = await Storage.getCurrentStoreDB();
      const url = ticketApi+`ticket/createSimplifiedTicket`;
      try {
        const response = await axios.post(url, this.data,{
          headers: {
            DB : window.btoa(DB)
          }
        });
        let data = response.data;
        responses.showInfo(`Ticket ${this.id?`Updated`:`Created`} Successfully`)
        return this.$router.push({ name : `TicketDetails`,params : { id : data.id } });
      } catch (error) {
        return errorHandler.tomcatError(error)
      }
    },
    getTicketById(){
      let id = this.$route.query.id;
      if (!id) return;
      this.$utils.showLoading();
      return this.$store.dispatch(`getTicketById`,id)
          .then(res=> {
            this.data.id = id;
            this.data.type = res.type;
            this.data.title = res.title;
            this.data.description = res.description;
            this.data.allStores = res.allStores;
            this.data.user = res.user;
          }).finally(()=>this.$utils.hideLoading());
    },
  }
};
</script>

<style scoped>
img {
  height: 130px;
  width: 130px;
  opacity: 0.7;
  border-radius: 10px;
  border: 1px black dashed;
}

.image-loader {
  height: 130px;
  width: 130px;
  border-radius: 10px;
  border: 1px black dashed;
}

.card {
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
  color: white;
}

.form-group {
  margin-bottom: 15px;
}

.form-check-label {
  margin-left: 5px;
}
</style>
