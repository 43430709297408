<template>
  <full-page title="Smart-Invoicing Signup">
    <div class="steps-container">
      <img class="m-2" height="120px" src="@/assets/img/screenshot.png">
      <div class="step card">
        <line-separator title="Business Representative Details" class="m-3"/>
        <span class="mt-3"><strong>Please fill in the form Below to sign up for ZRA Smart Invoicing.</strong></span>
        <div class="d-flex flex-column">
          <custom-input :animate="false" type="text" v-model="submitForm.name" placeholder="Full Names" class="mb-2"/>
          <custom-input :animate="false" type="email" v-model="submitForm.email" placeholder="Email Address"
                        class="mb-2"/>
          <custom-input :animate="false" type="text" v-model="submitForm.position" placeholder="Position" class="mb-2"/>
          <custom-input :animate="false" type="text" v-model="submitForm.telephoneNumber" placeholder="Phone No." class="mb-2"/>
          <custom-input :animate="false" type="text" v-model="submitForm.nrcOrPassportNo"
                        placeholder="NRC / Passport No." class="mb-2"/>
          <custom-input :animate="false" type="textarea" v-model="submitForm.postalAddress"
                        placeholder="Address Details" class="mb-2"/>

          <line-separator title="Please Sign Below"/>
          <vue-signature @setSignature="saveSignature"/>
        </div>
      </div>
      <!-- Submit Button -->
      <div class="submit-container">
        <button @click="handleSubmit" class="submit-button" :disabled="!canSubmit">Submit</button>
      </div>
    </div>

  </full-page>
</template>

<script>
import axios from 'axios';
import FullPage from "@/components/layout/utils/FullPage";
import { Storage } from "@/services/Storage";
import BubbleChart from "@/components/Charts/BubbleChart";
import LineSeparator from "@/components/layout/utils/LineSeparator";
import CustomInput from "@/components/layout/utils/CustomInput";
import VueSignature from "@/components/layout/utils/VueSignature";

export default {
  name: "SmartInvoicingSetup",
  data() {
    return {
      submitForm: {
        name: null,
        position: null,
        postalAddress: null,
        telephoneNumber: null,
        email: null,
        nrcOrPassportNo: null,
        signature: null,
      },
      files: [],
      commitmentForm: {
        DB: null,
        file: null
      },
      storesNotInvoiced: 0,
      hasSubmittedDocuments: false,
      signature: null,
    };
  },
  components: {VueSignature, CustomInput, LineSeparator, BubbleChart, FullPage},
  computed: {
    canSubmit() {
      return !!this.signature;
    }
  },
  beforeMount() {
    this.fetchStoreDetails();
  },
  methods: {
    async saveSignature(signatureFile) {
      signatureFile = signatureFile.replaceAll("data:image/png;base64,", "");
      this.signature = signatureFile;
    },
    async handleSubmit() {
      try {
        if (!this.canSubmit) return;
        let DB = await Storage.getCurrentStoreDB();
        DB = window.btoa(DB);
        let config = {
          headers: {
            DB: DB
          }
        };
        // loader here
        this.$utils.showLoading();
        await this.generatePDFFiles();
        const {data} = await axios.get(ticketApi + 'smartInvoiceOnboard/checkPaymentStatus', config);
        // let data = false;
        this.$utils.hideLoading();
        if (data) {
          let numberOfFiles = this.storesNotInvoiced + 1;
          return Swal.fire({
            title: "Enter your phone number",
            html: `Smart invoice sign ups require a payment of K1000 per store<br>Your total : ${this.$utils.formatMoney(1000 * numberOfFiles)}`,
            input: "text",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Send",
            cancelButtonText: "Cancel",
            showLoaderOnConfirm: true,
            allowOutsideClick: false,
            preConfirm: async (phoneNumber) => {
              if (!phoneNumber) {
                Swal.showValidationMessage("Phone number is required");
                return;
              }
              return this.$store.dispatch(`makePaymentForSmartInvoiceSignUp`, {
                phoneNumber,
                numberOfFiles
              }).then(() => this.submitFiles())
            },
          });
        } else {
          return this.submitFiles();
        }
      } catch (error) {
        this.$utils.hideLoading();
        return errorHandler.tomcatError(error);
      }
    },
    generatePDFFiles(){
      let obj = this.submitForm;
      obj.signature = this.signature;
      let methods = [
        this.$store.dispatch(`getCommitmentForm`,{ DB : this.commitmentForm.DB , ...obj })
            .then(res=>this.commitmentForm.file = res)
      ];
      for (let i = 0; i < this.files.length; i++) {
        let method = this.$store.dispatch(`getBranchForm`,{ DB : this.files[i].DB , ...obj })
            .then(res=>this.files[i].file = res);
        methods.push(method);
      }
      return Promise.all(methods);
    },
    async submitFiles() {

      let obj = {
        commitmentForm: this.commitmentForm,
        branchFiles: this.files
      };
      this.$utils.showLoading()
      return this.$store.dispatch(`submitSmartInvoiceSignUpDocs`, obj)
          .then((result)=>{
            return Swal.fire({
              icon: `success`,
              text: `Smart Invoice Signup Details Sent Successfully , Please review ticket to see the status`,
              showConfirmButton: true,
            }).then(()=>this.$router.push({ name : `TicketDetails`, path : { id : result.id } }));
          }).finally(() => this.$utils.hideLoading());
    },
    async setCommitmentForm(event) {
      // Set commitment form logic here
      const file = event.target.files[0];
      if (file) {
        let base64 = await this.getBase64(file);
        base64 = base64.replace('data:application/pdf;base64,', '');
        this.commitmentForm.file = base64;
      }
    },
    async setFile(event, index) {
      // Set files logic here
      const file = event.target.files[0];
      if (file) {
        let base64 = await this.getBase64(file);
        base64 = base64.replace('data:application/pdf;base64,', '');
        // this.$set(this.files, index, base64);
        this.files[index].file = base64;
      }
    },
    fetchStoreDetails() {
      this.$utils.showLoading();
      return this.$store.dispatch(`getFranchiseStoresByGroup`)
          .then(res => {
            let storesToSmartInvoice = res.filter(store => !store.isSmartInvoicing);
            if (storesToSmartInvoice.length > 0) {
              this.commitmentForm = {
                DB: window.btoa(storesToSmartInvoice[0].StoreDB),
                file: null
              };
            }
            this.storesNotInvoiced = storesToSmartInvoice.length - 1
            if (this.storesNotInvoiced > 0) {
              let otherStores = storesToSmartInvoice.filter((store, index) => index !== 0);
              let array = [];
              for (let i = 0; i < otherStores.length; i++) {
                array.push(otherStores[i])
              }
              this.files = array.map(store => {
                return {
                  DB: window.btoa(store.StoreDB),
                  file: null
                };
              })
            }
          }).finally(() => this.$utils.hideLoading());
    },
    async getBase64(file) {
      // Convert file to base64
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },

  }
};
</script>

<style lang="scss" scoped>
.full-page-title {
  text-align: center;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.centered-image {
  width: 100%; /* Adjust width as needed */
  height: auto; /* Maintain aspect ratio */
}

.steps-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
  padding: 20px;
  background-color: #f9f9f9;
}

.card {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 95%;
  max-width: 900px;
  transition: transform 0.2s, box-shadow 0.2s;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.step {
  margin: 20px 0;
}

.step h2 {
  font-size: 1.5rem;
  margin-bottom: 60px;
  color: #333;
}

p {
  font-size: 15rem;
}

.qr-code-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.file-uploader {
  display: inline-block;
}

.qr-code {
  margin-top: 20px;
}

.qr-code qriously {
  width: 200px;
  height: 200px;
}

a {
  color: #007bff;
  text-decoration: none;
  word-break: break-word;
}

a:hover {
  text-decoration: underline;
}

.pdf-upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.file-upload {
  margin: 2px 0;
  display: grid;
  flex-direction: column;
  align-items: center;
}

.file-upload label {
  font-size: 1rem;
  margin-bottom: 5px;
  color: #555;
}

.file-upload input[type="file"] {
  padding: 10px;
  font-size: 1rem;
}

.submit-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: bold;
  transition: background-color 0.3s, transform 0.2s;
  margin-bottom: 20px;
}

.submit-button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

.submit-button:active {
  background-color: #004080;
  transform: translateY(0);
}

.submit-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.submit-files-button {
  background-color: #28a745;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  margin-top: 10px;
  transition: background-color 0.3s, transform 0.2s;
}

.submit-files-button:hover {
  background-color: #218838;
  transform: translateY(-2px);
}

.input-and-download {
  display: flex; /* Use flexbox */
  align-items: center; /* Align items vertically in center */
}

.input-and-download input[type="file"] {
  /* Optional: Adjust input styling */
  margin-right: 10px; /* Example margin */
}

.submit-files-button:active {
  background-color: #1e7e34;
  transform: translateY(0);
}

</style>
