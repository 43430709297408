<template>
  <vue-bottom-sheet :background-clickable="false"
                    :background-scrollable="false"
                    :click-to-close="true"
                    :is-full-screen="true"
                    :maxHeight="'100vh'"
                    :overlay="true"
                    :rounded="true"
                    :swipe-able="true"
                    ref="detailSheet">
    <DiscountSetting @click="close"/>
  </vue-bottom-sheet>
</template>

<script>
import VueBottomSheet from "@webzlodimir/vue-bottom-sheet";
import DiscountSetting from "@/components/point-of-sale/sales/Discount/DiscountSetting";

export default {
  name: "DiscountSheet",
  components: {DiscountSetting, VueBottomSheet},
  methods: {
    open() {
      try {
        this.$refs.detailSheet.open();
      } catch (e) {
        console.error(e)
      }
    },

    close() {
      try {
        this.$refs.detailSheet.close();
      } catch (e) {
        console.error(e)
      }
    },
  }
}
</script>

<style scoped>

</style>
