<template>
  <full-page title="Tickets">
    <RangeSelector :submit-method="searchTickets"/>
    <div class="card p-2 mx-3">
      <div class="row">
        <div class="col-md-4">
          <label for="ticketTitle">Title / Description</label>
          <input class="form-control" type="text" id="ticketTitle" v-model="form.title">
        </div>

        <div class="col-md-4">
          <label for="ticketType" class="fw-bold">Type</label>
          <v-select id="ticketType" :reduce="item => item" v-model="form.type" label="Type" :options="ticketTypes">
            <template #no-options>
              <b>Type to search ...</b>
            </template>
          </v-select>
        </div>

        <div class="col-md-4">
          <label for="ticketLimit">Limit</label>
          <input class="form-control" type="number" id="ticketLimit" v-model="form.limit" min="1">
        </div>
      </div>
      <div class="form-switch mt-1">
        <input class="form-check-input" role="switch" v-model="form.allStores" type="checkbox" id="all-stores">
        <label class="form-check-label" for="all-stores">
          Show for Entire Franchise
        </label>
      </div>
    </div>
    <div class="card m-3">
      <div class="card-header d-flex justify-content-between align-items-center text-primary">
        <b style="text-underline: black">All Tickets</b>
      </div>

      <div class="card-body p-0">
        <b-tabs v-model="activeTab">
          <b-tab title="Open Tickets" @click="setActiveTab(0)">
            <div class="scrollable-container">
              <div class="row">
                <div class="col-12 col-sm-6 col-md-4 mb-3" v-for="ticket in filteredTickets" :key="ticket.id">
                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title">{{ ticket.title }}</h5>
                      <p class="card-text">
                        <span class="badge bg-info">{{ ticket.type }}</span>
                        <span class="badge bg-secondary">{{ ticket.status }}</span>
                      </p>
                      <p class="card-text">
                        <strong>Category:</strong> {{ ticket.category }}<br>
                        <strong>Sub-category:</strong> {{ ticket.subCategory }}<br>
                        <strong>Store:</strong> {{ ticket.store }}<br>
                        <strong>Assigned To:</strong> {{ ticket.assignedTo }}<br>
                        <strong>Created Date:</strong> {{ ticket.createdDate }}<br>
                        <strong>Due Date:</strong> {{ ticket.dueDate }}
                      </p>
                      <button class="btn btn-primary" @click="viewTicket(ticket.id)">
                        <font-awesome-icon icon="eye"/> View Details
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab title="Closed Tickets" @click="setActiveTab(1)">
            <div class="scrollable-container">
              <div class="row">
                <div class="col-12 col-sm-6 col-md-4 mb-3" v-for="ticket in filteredTickets" :key="ticket.id">
                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title">{{ ticket.title }}</h5>
                      <p class="card-text">
                        <span class="badge bg-info">{{ ticket.type }}</span>
                        <span class="badge bg-secondary">{{ ticket.status }}</span>
                      </p>
                      <p class="card-text">
                        <strong>Category:</strong> {{ ticket.category }}<br>
                        <strong>Sub-category:</strong> {{ ticket.subCategory }}<br>
                        <strong>Store:</strong> {{ ticket.store }}<br>
                        <strong>Assigned To:</strong> {{ ticket.assignedTo }}<br>
                        <strong>Created Date:</strong> {{ ticket.createdDate }}<br>
                        <strong>Due Date:</strong> {{ ticket.dueDate }}
                      </p>
                      <button class="btn btn-primary" @click="viewTicket(ticket.id)">
                        <font-awesome-icon icon="eye"/> View Details
                      </button>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
    <floating-add-button @click="$router.push({ name: 'CreateTicket' })"/>
  </full-page>
</template>

<script>
import axios from 'axios';
import FullPage from "@/views/point-of-sale/tables/FullPage";
import RangeSelector from "@/views/point-of-sale/tables/RangeSelector";
import FloatingAddButton from "@/views/point-of-sale/tables/FloatingAddButton";
import { Storage } from "@/services/Storage";
import { mapGetters } from 'vuex';

export default {
  name: "Tickets",
  components: { FloatingAddButton, RangeSelector, FullPage },
  data() {
    return {
      activeTab: 0,
      pageOptions: {
        enabled: true,
        perPage: 10,
        // Add more pagination options if needed
      },
      tickets: [], // Empty array, will be populated by API
      form: {
        title: null,
        limit: 50,
        type: null,
        allStores: true,
      },
    };
  },
  computed: {
    ...mapGetters(['getGlobalDateRange']),
    filteredTickets() {
      // Make sure this.tickets is populated correctly
      return this.tickets;
    },
    ticketTypes() {
      return [
        'error',
        'featureRequest',
        'billing',
        'consultation',
        'calloutRequest'
      ];
    },
  },
  methods: {
    setActiveTab(tab) {
      this.activeTab = tab;
      this.searchTickets();
    },
    viewTicket(id) {
      return this.$router.push({ name: 'TicketDetails', params: { id } });
    },
    async searchTickets() {
      try {
        let DB = await Storage.getCurrentStoreDB();
        DB = window.btoa(DB);
        let config = {
          headers: {
            DB
          },
          params: {
            ...this.$store.getters.getGlobalDateRange,
            closed: this.activeTab !== 0,
            ...this.form
          }
        };

        this.$utils.showLoading();
        const response = await axios.get(ticketApi + 'ticket/searchByStore', config);
        this.$utils.hideLoading();

        console.log('Tickets:', response.data); // Add this line
        this.tickets = response.data;
      } catch (error) {
        this.$utils.hideLoading();
        console.error('Error fetching tickets:', error);
        return errorHandler.tomcatError(error);
      }
    }

  },
  mounted() {
    this.searchTickets();
  }
};
</script>

<style scoped>
/* Ensure cards are responsive */
.card {
  margin-bottom: 1rem;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
}

.card-body {
  padding: 1rem;
}

.card-title {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

.card-text {
  margin-bottom: 0.5rem;
}

.badge {
  margin-right: 0.5rem;
}

/* Adjust scrollable container to fit content */
.scrollable-container {
  max-height: 500px; /* Adjust height as needed */
  overflow-y: auto;
  padding-right: 15px; /* Add space for scrollbar */
}
</style>
