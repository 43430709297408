<template>
  <div class="container-fluid">
    <div class="row">
      <div class="stock-search-bar">
        <button class="barcode-scanner text-white" @click="openTables">
          <font-awesome-icon icon="fa-solid fa-table"/>
        </button>
        <font-awesome-icon class="search-icon position-absolute" icon="fa-solid fa-magnifying-glass"/>

        <input v-model="input" @keyup="search($event)" @keyup.enter="getStock"
               class="ml-2 stock-search-field form-control"
               placeholder="PLU, Barcode,Description" type="text">


        <button @click="open" class="barcode-scanner viewOrder text-white">
          <span class="badge" v-if="saleUnits>0">{{ saleUnits }}</span>
          <font-awesome-icon icon="fa-solid fa-cart-shopping"/>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StockSearchBar",
  components: {},
  computed: {
    saleUnits() {
      return this.$store.getters.getSaleUnits
    },
    category() {
      return this.$store.getters.getCurrentCategory
    },
    subCategory() {
      return this.$store.getters.getSubCategory
    },
    sub2Category() {
      return this.$store.getters.getSubCategory2
    },
    isTabOpen() {
      return this.$store.getters.getIsTabOpen
    }
  },
  data() {
    return {
      input: '',

    }
  },
  methods: {
    open() {
      this.$router.push({name: 'viewSale'})
    },
    search(e) {
      this.getStock()
    },
    async openTables() {
      let isAllowed = await this.$store.dispatch(`validateSecurityPermission`, 'LoadTable');
      if(!isAllowed) return;
      return this.$router.push({name: 'tables'})
    },
    onBarcodeScanned(barcode) {
      if (barcode.slice(-5) === `Enter`) {
        barcode = barcode.slice(0, -5);
      }
      if (!this.isTabOpen) {
        try {
          let item;

          if (barcode.length === 13) {
            let scaleItemPLU = barcode.toString().substring(2, 6);
            item = this.$store.getters.getByPlu(scaleItemPLU)
            if (item) {
              let barcodePrice = barcode.toString().substring(6, 12);
              barcodePrice = barcodePrice.substring(0, 4) + '.' + barcodePrice.substring(4, 6);
              barcodePrice = Number(barcodePrice);
              let qty = barcodePrice / item.SellingIncl;
              item.Qty = parseFloat(qty.toFixed(3));
              return this.addScaleItem(item);
            }
          }

          item = this.$store.getters.getByPlu(barcode)
          if (!item) {
            item = this.$store.getters.getByPlu(barcode.slice(1))
          }

          if (!item) {
            let itemBarcode = barcode.slice(0, -1);
            item = this.$store.getters.getByPlu(itemBarcode)
          }

          if (item) {
            this.addToSale(item)
          } else {
            return responses.showWarning('Item not found')
          }
        } catch (e) {
          console.error(e)
        } finally {
          this.input = ''
        }
      }
    },
    addScaleItem(itemObj) {
      return this.$store.dispatch('getStockSetting', itemObj.PLU).then((set) => {
        if (set.cookingLink) {
          this.$router.push({name: 'instruction', params: {plu: itemObj.PLU}})
          return;
        }
        let price = itemObj.SellingIncl;
        let specialPrice = this.$store.getters.getItemSpecialPrice(itemObj.PLU)
        if (specialPrice) {
          responses.showSuccess(`Special Detected`)
          price = specialPrice;
        }
        let obj = {
          Plu: itemObj.PLU,
          Description: itemObj.Description,
          Qty: itemObj.Qty,
          Unit: Number(price).toFixed(2),
          Line: Number(price * itemObj.Qty).toFixed(2),
          Discount: 0,
          vat: 0,
          instructions: []
        }
        this.$store.commit('addToCurrentSaleLines', obj)
      }).catch(e => log('An error occurred'))
    },
    addToSale(item) {
      this.$store.dispatch('getStockSetting', item.PLU).then((set) => {
        if (set.cookingLink) {
          this.$router.push({name: 'instruction', params: {plu: item.PLU}})
          return;
        }
        let price = item.SellingIncl;
        let specialPrice = this.$store.getters.getItemSpecialPrice(item.PLU)
        if (specialPrice) {
          responses.showSuccess(`Special Detected`)
          price = specialPrice;
        }
        let obj = {
          Plu: item.PLU,
          Description: item.Description,
          Qty: 1,
          Unit: Number(price).toFixed(2),
          Line: Number(price).toFixed(2),
          Discount: 0,
          vat: 0,
          instructions: []
        }
        this.$store.commit('addToCurrentSaleLines', obj)
      }).catch(e => log('An error occurred'))
    },
    getStock() {
      const query = {
        description: this.input,
        category: '',
        subCategory: '',
        sub2Category: ''
      }
      this.$store.dispatch('searchDescription', query)
    },
  },
  created() {
    this.$barcodeScanner.init(this.onBarcodeScanned)
  },
  destroyed() {
    this.$barcodeScanner.destroy()
  }
}
</script>

<style lang="scss" scoped>
.row {
  background: #9ccc65;
  padding: 0 10px;
}

button {
  background: #9ccc65;
}

.stock-search-bar {
  background: #92A7BA;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  border-radius: 10px 10px 0 0;
  justify-content: flex-start;
  position: relative;

  .barcode-scanner {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    font-size: 24px;
  }

  .viewOrder {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;

    .badge {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: -10px;
      right: -10px;
      font-size: 8px;
      width: 24px;
      height: 24px;
      background: #9ccc65;
      border-radius: 50%;
    }
  }

  .search-icon {
    left: 67px;
    font-size: 18px;
    color: #40434A;
  }

  .stock-search-field {
    margin-left: 10px;
    height: 40px;
    width: 100%;
    border-radius: 10px;
    padding-left: 30px;
    outline: transparent;
  }
}
</style>
