<template>
  <div class="page">
    <BasicCashupForm/>
  </div>
</template>

<script>
import BasicCashupForm from "@/components/point-of-sale/cashup/BasicCashupForm";
export default {
  name: "CashupView",
  components: {BasicCashupForm}
}
</script>
