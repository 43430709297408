<template>
  <h1 class="card-title my-2 text-dark text-uppercase px-2" style="font-weight:700"><span
      style="color: #9ccc65">{{ first }}</span> {{rest}}</h1>
</template>
<script>
export default {
  name: 'TitleHeader',
  props: {
    title: {
      type : String ,
      required : true
    }
  },
  computed:{
    first(){
      let i = this.title.indexOf(" ");
      if(i<=0){
        return this.title;
      }
      return this.title.substring(0,i);
    },
    rest(){
      let i = this.title.indexOf(" ");
      if(i<=0){
        return this.title;
      }
      return this.title.substring(i);
    }
  },
}
</script>
