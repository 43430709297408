export const Device = {
    getDeviceId: async () => {
        let id = localStorage.getItem('_capuid');
        let imei = undefined;
        try {
            imei = await Imei.get();
            if (imei === undefined || imei === null) {
                throw new Error(`Could not get IMEI for device`)
            }else if (imei !== id) {
                id = imei
            }
        }catch (e) {
            //errorHandler.tomcatError(e,false,`Device Error`)
        }
        if (id) return id;
        id = generateUUID();
        localStorage.setItem('_capuid' , id);
        return id;
    },
}
