<template>
  <div class="page">
    <TitleHeader :title="title" />
    <slot/>
  </div>
</template>

<script>
import TitleHeader from "@/components/layout/utils/TitleHeader";
export default {
  name: "FullPage",
  components: {TitleHeader},
  props : {
    title : {
      type : String,
      required : true,
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  height: 100%;
  background: white;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
}
</style>