<template>
  <div class="page">
    <PaymentScreen/>
  </div>
</template>

<script>
import PaymentScreen from "@/components/point-of-sale/sales/payment/PaymenScreen";

export default {
  name: "PaymentView",
  components: {PaymentScreen},
}
</script>

<style lang="scss" scoped>
.page {
  background: white;
}
</style>
