<template>
  <label>
    {{ label }}
    <textarea :readonly="readOnly" :value="value" @input="updateValue" rows="3" :placeholder="typedText" v-if="type === `textarea`" :required="required" class="form-control"></textarea>
    <input :readonly="readOnly" v-else-if="type===`number`" :value="value" :step="step" @input="updateValue" :required="required" :type="type" class="form-control" :placeholder="typedText">
    <input :readonly="readOnly" v-else :value="value" @input="updateValue" :type="type" class="form-control" :required="required" :placeholder="typedText">
  </label>
</template>

<script>
export default {
  name: "CustomInput",
  mounted() {
    this.startTypingEffect();
  },
  data() {
    return {
      typedText: '',
      typingInterval: null,
    }
  },
  props: {
    value: {
      required: true,
    },
    type: {
      type: String,
      default: "text"
    },
    step: {
      type: String,
      default: "0.01"
    },
    placeholder: {
      type: String,
      default: "Type something"
    },
    label: {
      type: String,
      default: ""
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: true
    },
    animate: {
      type: Boolean,
      default: true
    },
  },
  methods: {
    updateValue(event) {
      this.$emit('input', event.target.value);
    },
    startTypingEffect() {
      const text = this.placeholder;
      if (!this.animate){
        this.typedText = this.placeholder;
        return;
      }
      let index = 0;
      this.typingInterval = setInterval(() => {
        if (index < text.length) {
          this.typedText += text[index];
          index++;
        } else {
          setTimeout(() => {
            this.typedText = '';
            index = 0;
          }, 1000);
        }
      }, 100);
    },
  }
}
</script>

<style scoped>

</style>