<template>
  <div>
    <button @click="toggle" class="item-list-toggle" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseExample"
            aria-expanded="false" aria-controls="collapseExample">
      Item List
    </button>
    <div class="collapse" id="collapseExample">
      <ul class="sales-list">
        <li :key="sale.Plu" v-for="sale in currentSaleLines">
          <CurrentSaleLine :sale-line="sale"/>
        </li>
        <li>
          <button @click="toggle" class="block" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseExample"
                  aria-expanded="false" aria-controls="collapseExample">
            <font-awesome-icon icon="fa-solid fa-chevron-up"/>
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import CurrentSaleLine from "@/components/point-of-sale/sales/CurrentSaleLine";

export default {
  name: "ViewItems",
  components: {CurrentSaleLine},
  computed: {
    currentSaleLines() {
      return this.$store.getters.getSaleLines
    }
  },
  methods: {
    toggle() {
    }
  },
}
</script>

<style scoped>
.sales-list {
  padding: 0 0 70px;
  margin: 0;
  list-style: none;
}

.item-list-toggle {
  width: 100%;
  height: 30px;
  text-align: left;
  font-weight: 600;
}

.block {
  width: 100%;
  height: 40px;
  font-size: 30px;
  background: transparent;
}
</style>
