<template>
  <div class="qty-component d-flex justify-content-around">
    <button class="rmv-qty-btn text-white" @click="removeFromCart">
      <font-awesome-icon icon="fa-solid fa-minus"/>
    </button>
    <input class="qty-input" v-model="saleLine.Qty" @input="recalculateTip" type="number" :readonly="isReadOnly">
    <button class="add-qty-btn text-white" @click="addToCart">
      <font-awesome-icon icon="fa-solid fa-plus"/>
    </button>
  </div>
</template>

<script>
export default {
  name: "CurrentSaleQty",
  computed : {
    deviceSettings(){
      return this.$store.getters.getDeviceSettings;
    },
    isReadOnly() {
      if (!this.deviceSettings) return true;
      return !this.deviceSettings.SelectQty;
    },
  },
  props: {
    saleLine: {
      type: Object,
      required: true
    }
  },
  methods: {
    addToCart() {
      return this.$store.dispatch('getStockSetting', this.saleLine.Plu)
          .then(async (set) => {
            let price = Number(this.saleLine.Unit.toFixed(2));
            let description = this.saleLine.Description;

            if (set.askForPrice){
              price = (await this.askForPrice(this.saleLine));
            }
            if (set.cookingLink) {
               return this.$router.push({name: 'instruction', params: {plu: this.saleLine.Plu}})
            }

            let specialPrice = this.$store.getters.getItemSpecialPrice(this.saleLine.Plu)
            if (specialPrice){
              price = specialPrice;
            }

            let obj = {
              Plu: this.saleLine.Plu,
              Description: description,
              Qty: 1,
              Unit: price,
              Line: price,
              Discount: 0,
              vat: 0,
              instructions: []
            }
            this.$store.commit('addToCurrentSaleLines', obj)
            return this.recalculateTip();
          })
    },

    recalculateTip(){
      return this.$store.dispatch('calculateTipAmount')
    },

    removeFromCart() {
      return this.$store.commit("voidItem", this.saleLine);
    },

    askForPrice(item) {
      return Swal.fire({
        allowOutsideClick: false,
        showCancelButton: true,
        showLoaderOnConfirm: true,
        title: `Set Selling Price`,
        html: `<h6 >Description : ${item.Description}</h6>
               <h6 >Current Price : ${item.Unit.toFixed(2)}</h6>
               <input type="number" step="0.01" id="selling-prc" class="swal2-input" placeholder="Selling Price" style="max-width: 70%">`,
        confirmButtonText: 'Set Price',
        focusConfirm: false,
        preConfirm: async () => {
          let value = Swal.getPopup().querySelector('#selling-prc').value
          if (!value) {
            Swal.showValidationMessage(`Please enter a valid Selling Price`)
          }
          return parseFloat(value);
        }
      }).then(result=>{
        if (result.isConfirmed){
          return result.value;
        }
        return parseFloat(item.SellingIncl.toFixed(2))
      })
    },
  },
}
</script>

<style scoped>
.qty-component {
  gap: 5px;
}

.qty-input {
  width: 50px;
  border: 1px solid #C9CACF;
  border-radius: 10px;
  text-align: center;
}

.add-qty-btn, .rmv-qty-btn {
  border-radius: 10px;
  width: 30px;
  height: 30px;
}

button {
  background: #9ccc65;
}
</style>
