import {responses} from "@/utils/responses";
import {Storage} from "@/services/Storage";
import axios from "axios";
import router from "@/router";

export default {
    name: 'stock',
    state: {
        stock: [],
        stockItem: {},
        stockCache: new Map()
    },
    getters: {
        getStockProducts: (state) => state.stock,
        getAllStockProducts: ({stockCache}) => [...stockCache.values()],
        getStockItem: (state) => state.stockItem,
        getByPlu: (state) => (plu) => state.stockCache.get(plu),
    },

    mutations: {
        setStock: async (state, payload) => {
            payload.sort((a, b) => a.Description.localeCompare(b.Description))
            if (payload) {
                await Storage.setStock(payload);
                state.stock = payload.filter(x => x.FastLine === 1)
                    .sort((a, b) => a.Description.localeCompare(b.Description));
                //state.allStock = payload
                state.stockCache = payload.reduce((mapAcc, obj) => {
                    mapAcc.set(obj.PLU, obj);
                    return mapAcc;
                }, new Map())
            } else {
                await Storage.getFastLine().then(res => {
                    state.stock = res.sort((a, b) => a.Description.localeCompare(b.Description))
                })
            }
        },
        setStockSetting: async (state, payload) => {
            if (payload) {
                await Storage.setStockSettings(payload);
            }
        },
        setSearch: (state, payload) => {
            state.stock = payload.sort((a, b) => a.Description.localeCompare(b.Description))
        },
        setStockItem: (state, payload) => {
            state.stockItem = payload
        },
        editStock: (state, payload) => {
            Storage.editStockItem(payload).catch();
            state.stockCache = payload.map(s => {
                if (s.PLU === payload.PLU) {
                    return payload;
                }
                return s;
            }).reduce((mapAcc, obj) => {
                mapAcc.set(obj.PLU, obj);
                return mapAcc;
            }, new Map())
            state.stock = [...state.stockCache.values()];
            responses.showSuccess('Item updated successfully!');
        },
        addStockItem: (state, payload) => {
            Storage.getAllStock().then(stock => {
                state.stock = state.stock.map(s => {
                    if (s.PLU === payload.PLU) {
                        return payload;
                    }
                    return s;
                })
                return Storage.setStock(state.stock);
            });
        }
    },
    actions: {
        addStockItem: async (context, payload) => {
            let store = await Storage.getDefaultStore();
            try {
                delete payload.CreatedUser
            } catch (e) {
                console.error(e)
            }
            let user = await Storage.getCurrentUser();
            return axios.post(`${franchiseTomcatApi}createItem/${store.StoreDB}`, payload)
                .then(async ({data}) => {
                    if (payload.QTY) {
                        let adj = {
                            user: user.Username,
                            pc: 'Online',
                            plu: payload.PLU,
                            reason: "Kazang POS initial qty",
                            qty: parseFloat(payload.QTY),
                            adjustmentType: 2,
                        };
                        try {
                            axios.post(serverApi + `stock/${store.StoreDB}/adjustment`, adj)
                        } catch (e) {
                        }
                    }
                    context.commit('addStockItem', data);
                    context.commit('setStockItem', data);
                    return data;
                })
                .catch((err) => errorHandler.tomcatError(err)).finally(() => context.dispatch('getAllStock'))
        },
        viewStockItem: (context, payload) => {
            return new Promise(resolve => {
                context.commit('setStockItem', payload)
                resolve();
            })
        },
        getStockSetting: (context, plu) => {
            return Storage.getStockSetting(plu)
        },
        getAllStock: async (context) => {
            let store = await Storage.getDefaultStore();
            let device = localStorage.getItem('_capuid');
            let user = await Storage.getCurrentUser();
            if (!store) return;
            return axios.get(serverApi +`stock/${store.StoreDB}`,  {params: {pc: device,user : user?user.Username:undefined}})
            // return axios.get(serverApi + `stock/${store.StoreDB}`,{params: {pc: device,user : user?user.Username:undefined}})
                .then(({data}) => {
                    const stockSettings = data.map(s => {
                        let set = s.StockSetting
                        if (set) {
                            return {
                                plu: s.PLU,
                                cookingLink: set.CookingLink,
                                mtv: set.mtv,
                                askForPrice: set.askForPrice,
                                askForDescription: set.askForDescription,
                                askForBoth: set.askForBoth,
                                NonStock: set.NonStock,
                            }
                        } else {
                            return {
                                plu: s.PLU,
                                mtv: 0.00,
                                NonStock: false,
                                cookingLink: false,
                                askForPrice: false,
                                askForDescription: false,
                                askForBoth: false,
                            }
                        }
                    })
                    const stock = data.map(s => {
                        delete s.CreatedDate
                        delete s.Image
                        delete s.Price1
                        delete s.Price2
                        delete s.Price3
                        delete s.Price4
                        delete s.Price5
                        delete s.args
                        delete s.where_between
                        delete s.StockSetting
                        return s;
                    })
                    context.commit('setStock', stock);
                    context.commit('setStockSetting', stockSettings);
                }).catch((err) => errorHandler.tomcatError(err))
        },
        searchStock: (context, query) => {
            context.commit('setCurrentCategory', query.category)
            context.commit('setSubCategory', query.subCategory)
            context.commit('setSubCategory2', query.sub2Category)
            return Storage.searchStock(query).then(res => {
                context.commit('setSearch', res)
            }).catch(err => console.error(err))
        },
        searchDescription: (context, query) => {
            context.commit('setCurrentCategory', query.category)
            context.commit('setSubCategory', query.subCategory)
            context.commit('setSubCategory2', query.sub2Category)
            return Storage.searchStockDesc(query).then(res => {
                context.commit('setSearch', res)
            }).catch(err => console.error(err))
        },

        searchDepartment: async (context, query) => {
            query.description = '';
            return Storage.searchStockDesc(query).then((value => value));
        },

        getFastLine: (context, query) => {
            return Storage.getFastLine().then(res => {
                context.commit('setSearch', res)
            }).catch(err => console.error(err))
        },
        adjustStock: (context, plu) => {
            let item = context.getters.getByPlu(plu);
            return Swal.fire({
                allowOutsideClick: false,
                showCloseButton: true,
                title: `Stock Code : ${plu}`,
                html: `<h4 >Description : ${item.Description}</h4>
               <h5 >Current QTY : ${item.QTY}</h5>

               <label for="qty"> New Qty </label>
               <input type="number" step="0.001" id="qty" class="swal2-input" placeholder="New Stock Qty" style="max-width: 70%">`,
                confirmButtonText: 'Adjust Qty',
                focusConfirm: false,
                preConfirm: () => {
                    let new_qty = Swal.getPopup().querySelector('#qty').value
                    if (!new_qty) {
                        Swal.showValidationMessage(`Please enter a valid new Stock Qty`)
                    }
                    return {qty: new_qty}
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    item.QTY = result.value.qty
                    context.commit('editStock', item)
                    return Swal.fire(
                        'Done!',
                        `${item.Description} quantity has been set to ${result.value.qty}.`,
                        'success'
                    );
                }
            })
        },
        uploadAProducePic: (context, data) => {
            console.log(data)
        },

        updateCodeApi: async (context, data) => {
            let store = await Storage.getDefaultStore();
            return axios.put(franchiseTomcatApi + `stock/${store.StoreDB}/updatePLU`, data)
                .then(() => context.dispatch('getAllStock'))
                .catch((err) => errorHandler.tomcatError(err))
        },

        changeCode: (context, plu) => {
            return Swal.fire({
                title: 'Change Stock Code / Barcode',
                icon: 'info',
                input: 'text',
                showCancelButton: true,
                confirmButtonText: 'Save',
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                preConfirm: async (input) => {
                    if (!input) return Swal.showValidationMessage(`New Code required`)
                    let check = await Storage.getByPlu(input);
                    if (check) return Swal.showValidationMessage(`Stock Code ${input} is Already in Use!`)
                    return context.dispatch('updateCodeApi', {
                        'oldPLU': plu,
                        'newPLU': input,
                    }).then(() => input)
                        .catch(({message}) => Swal.showValidationMessage(`Request failed: ${message}`))
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    return Swal.fire({
                        title: `Success`,
                        icon: 'success',
                        text: `Stock Code ${result.value} Saved`
                    }).then(() => router.push({name: 'stock'}))
                }
            })
        },

        getStockByDepartment : async (context , payload) => {
            let store = await Storage.getDefaultStore();
            return axios.get(franchiseTomcatApi+`stock/${store.StoreDB}/getByDepartment`,{params : payload})
                .then(({data})=>data).catch(err=>errorHandler.tomcatError(err));
        },

        getAvailableStock : async (context) => {
            let store = await Storage.getDefaultStore();
            return axios.get(franchiseTomcatApi+`stock/${store.StoreDB}/availableStock`)
                .then(({data})=>data).catch(err=>errorHandler.tomcatError(err));
        },
    }
}
