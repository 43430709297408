<template>
  <div class="page">
    <div class="container">
      <div class="row py-3 d-flex justify-content-center align-items-center ">
        <div class="amount-box col-2">
          <h1 class="amount-paid change-amount">{{ $utils.formatMoney(receipt.paidAmount) }}</h1>
          <h4 class="amount-label">Total Paid</h4>
        </div>
        <div class="amount-box col-2 amount">
          <h1 class="amount-paid change-amount">{{ $utils.formatMoney(receipt.change - receipt.tip) }}</h1>
          <h4 class="amount-label">Cash Change</h4>
        </div>
      </div>
      <div class="d-flex flex-column justify-content-center align-items-center">
        <div class="email-box mb-2">
          <input class="email-fie ld form-control" placeholder="Enter Email" type="email" v-model="email">
        </div>
      </div>

      <div class="d-flex flex-column justify-content-center align-items-center mb-2">
        <qriously v-if="receipt.zraInvoiceQrCode"
                  :value="receipt.zraInvoiceQrCode"
                  :size="200">
        </qriously>
        <span class="text-sm text-secondary">View your A4 Invoice By Scanning the above QR Code</span>
      </div>

      <div class="row d-flex justify-content-center align-items-center fixed-bottom mb-3 gap-2 mx-2">
        <button @click="reprintLastInvoice" class="col-md-6 btn mx-2 btn-primary reprint-btn">Reprint</button>
        <button @click="sendEmail" class="col-md-6 next-button cent">Next Sale</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ViewInvoicesView",
  data() {
    return {
      email: '',
      config: {}
    }
  },
  computed: {
    receipt() {
      return this.$store.getters.getLatestInvoice
    },
  },
  methods: {
    nextSale() {
      return this.$router.push({name: 'pos'})
    },
    reprintLastInvoice() {
      return this.$store.dispatch(`reprintLastInvoice`);
    },
    sendEmail() {
      if (this.email) {
        let emailObj = {
          email: this.email,
          inv: this.receipt.invoice
        };
        this.$store.dispatch('sendInvoiceDetails', emailObj)
      }
      Promise.all([
        this.nextSale(),
        this.$store.dispatch(`getAllStock`),
        this.$store.dispatch(`getAllActiveSpecials`),
      ])
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  background: white;
  height: 100%;
}

.amount-box {
  height: 100px;
  width: 50%;
  display: flex;
  flex-direction: column;

  .amount-label {
    color: #40434A;
  }

  .amount-paid {
    font-size: 2rem;
    padding: 0;
    margin: 0;
    outline: transparent;
    border: none;
    height: 44px;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
  }
}

.amount-box:first-child {
  align-items: end;

  .amount-paid {
    padding-bottom: 9px;
    text-align: right;
    max-width: 250px;
    width: 100%;

    &:focus, &:active {
      border-bottom: 3px solid #9ccc65 !important;
    }
  }
}

.amount-box:nth-child(2) {
  align-items: start;
  border-left: 1px solid #d2d2d2;
}

.email-box {
  position: relative;
  max-width: 500px;
  width: 100%;
  height: 45px;

  .email-field {
    width: 100%;
    height: 100%;
  }

  .send-email {
    color: white;
    font-size: 20px;
    height: 100%;
    width: 45px;
    border-radius: 10px;
    position: absolute;
    top: 0;
    right: 9px;
    bottom: 0;
  }
}

button {
  width: 100%;
  font-weight: 700;
  height: 60px;
  border-radius: 10px;
  max-width: 500px;
}

.next-button {
  background: #9ccc65;
  color: white;

}

.text-sm {
  font-size: 12px;
}
</style>
