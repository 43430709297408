import { render, staticRenderFns } from "./CustomModal.vue?vue&type=template&id=6d238930&scoped=true"
import script from "./CustomModal.vue?vue&type=script&lang=js"
export * from "./CustomModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d238930",
  null
  
)

export default component.exports