import store from '../store/index'
import TimeManager from "@/services/TimeManager";
function formatNumber(decimalCount, amount, symbol, thousands, decimal) {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        // Start by converting the amount to a fixed-point notation
        amount = Number(amount);
        let formattedAmount = amount.toFixed(decimalCount);

        // Remove unnecessary trailing zeros and the decimal point if it is an integer
        formattedAmount = parseFloat(formattedAmount).toString();

        let i = parseInt(formattedAmount).toString();
        let j = i.length > 3 ? i.length % 3 : 0;

        return (
            `${symbol} ` +
            negativeSign +
            (j ? i.substr(0, j) + thousands : "") +
            i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
            (decimalCount && formattedAmount.indexOf('.') !== -1
                ? decimal + formattedAmount.split('.')[1]
                : "")
        );
    } catch (e) {
        console.error(e);
    }
}

export const utils = {
    toPascalCase(string) {
        return string
            .replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())
            .replace(/^\w/, s => s.toUpperCase());
    },
    cutLength(string, limit = 45) {
        if (string.length < limit) {
            return string
        } else {
            return string.toString().substring(0, limit).concat('...')
        }
    },
    formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",",isCurrency = true) {
        let currency = store.getters.getBaseCurrency.Symbol;
        if (!isCurrency || !currency){
            return formatNumber(decimalCount, amount, '', thousands, decimal);
        }
        return formatNumber(decimalCount, amount, currency, thousands, decimal);
    },
    formatQTY(amount) {
        return this.formatMoney(amount, 3, '.', ',',false);
    },
    removeIf(arr, predicate) {
        return arr.filter(predicate);
    },
    remove(arr, obj) {
        return arr.filter(v => v !== obj)
    },
    displayDate(date , isDateTime = true) {
        return TimeManager.shortenDisplay(date , isDateTime);
    },

    displayJavaDate(date) {
        return TimeManager.shortJavaDateTimeObject(date);
    },
    showLoading : ()=>store.commit(`setLoadingState`,true),
    hideLoading : ()=>store.commit(`setLoadingState`,false),
}
