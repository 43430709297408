<template>
  <div class="page">
    <BackNavigationBar title="Payment Type : Split" />
    <SplitPaymentScreen />
  </div>
</template>

<script>
import SplitPaymentScreen from "@/components/point-of-sale/sales/payment/SplitPaymentScreen";
import BackNavigationBar from "@/components/layout/navigation/BackNavigationBar";
export default {
  name: "SplitPaymentView",
  components: {BackNavigationBar, SplitPaymentScreen}
}
</script>

<style scoped>
.page {
  height: 100vh;
  overflow-y: scroll;
}
</style>