import axios from "axios"
import {Storage} from "@/services/Storage";

export default {
    name: 'supplier',
    state: {
        suppliers : [],
        selectedSupplierCode : ""
    },
    getters: {
        getAllSuppliers: (state) => state.suppliers,
        getSelectedSupplierCode : (state) => state.selectedSupplierCode
    },

    mutations: {
        setSuppliers: (state, data) => state.suppliers = data,
        setSelectedSupplierCode : (state, data) => state.selectedSupplierCode = data
    },
    actions: {
        getAllSuppliers: async (context) => {
            let store = await Storage.getDefaultStore();
            return axios.get(franchiseTomcatApi + `supplier/${store.StoreDB}/getAll`)
                .then(({data})=> context.commit('setSuppliers', data))
                .catch((err) => errorHandler.tomcatError(err))
        },

        createSupplier: async (context, payload) => {
            let store = await Storage.getDefaultStore();
            let user = await Storage.getCurrentUser();
            payload.user = user.Username;
            return axios.post(franchiseTomcatApi + `supplier/${store.StoreDB}`,payload)
                .catch((err) => errorHandler.tomcatError(err))
        }
    }
}
