<template>
  <div class="category-bar justify-content-start align-items-center container-fluid">
    <button @click="clearCategory" class="home-button">
      <font-awesome-icon icon="fa-solid fa-house"/>
    </button>
    <div class="category-list">
      <div class="select category main-category">
        <select @change="selectCat" v-model="category">
          <option value="__________">__________</option>
          <option :value="cat" v-for="cat in main">{{ $utils.toPascalCase(cat) }}</option>
        </select>
      </div>
      <div class="select category sub-category select" v-if="notNull(category)">
        <font-awesome-icon class="cat-icon" icon="fa-solid fa-angle-right"/>
        <select @change="selectSub" v-model="subCategory">
          <option value="__________">__________</option>
          <option :value="cat" v-for="cat in subCat">{{ $utils.toPascalCase(cat) }}</option>
        </select>
      </div>
      <div class="select category sub2-category select" v-if="notNull(subCategory)">
        <font-awesome-icon class="cat-icon" icon="fa-solid fa-angle-right"/>
        <select v-model="subCategory2" @change="setSub2">
          <option value="__________">__________</option>
          <option :value="cat" v-for="cat in sub2Cat">{{ $utils.toPascalCase(cat) }}</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CategoryBar",
  computed: {
    categories() {
      return this.$store.getters.getCategories
    }, main() {
      return this.categories.map(cat => cat.name)
    }, subCat() {
      return this.$store.getters.getSubDepartments(this.category).map(cat => cat.name)
    }, sub2Cat() {
      return this.$store.getters.getSub2Departments(this.category, this.subCategory).map(cat => cat.name)
    }
  },
  data() {
    return {
      default: '__________',
      category: '__________',
      subCategory: '__________',
      subCategory2: '__________'
    }
  },
  beforeMount() {
    this.clearCategory();
  },
  methods: {
    selectCat() {
      this.subCategory = this.default;
      this.subCategory2 = this.default;
      if (this.category !== this.default) {
        this.$store.dispatch('searchStock', {
          category: this.category,
          subCategory: '',
          sub2Category: ''
        })
      } else {
        this.$store.dispatch('getFastLine')
      }
    },
    selectSub() {
      this.subCategory2 = this.default;
      if (this.subCategory !== this.default) {
        this.$store.dispatch('searchStock', {
          category: this.category,
          subCategory: this.subCategory,
          sub2Category: ''
        })
      } else {
        this.selectCat();
      }
    },

    setSub2() {
      if (this.subCategory2 !== this.default) {
        this.$store.dispatch('searchStock', {
          category: this.category,
          subCategory: this.subCategory,
          sub2Category: this.sub2Category
        })
      } else {
        this.selectSub()
      }
    },
    notNull(val) {
      return val !== '' && val !== undefined && val !== this.default
    },
    clearCategory() {
      this.category = this.default
      this.subCategory = this.default
      this.subCategory2 = this.default
      this.$store.dispatch('getFastLine')
    }
  }
}
</script>

<style lang="scss" scoped>
@media (max-width: 400px) {
  .category-bar {
    overflow-y: hidden;
  }
}

.cat-icon {
  font-size: 20px;
}

.category-bar {
  height: 50px;
  padding: 0 10px;
  background: white;
  display: inline-flex;

  .category-list {
    display: inline-flex;
    overflow-y: hidden;

    h6 {
      padding: 0;
      margin: 0;
    }

    .category {
      padding: 5px;
      display: inline-flex;
      gap: 3px;
      margin-left: 10px;
      font-size: 14px;
      align-items: center;
      //width: 150px;
    }
  }

}

.home-button {
  color: #40434A;
  background: transparent;
  width: 40px;
  height: 40px;
}

.select {
  position: relative;
  display: inline-block;
  //margin-bottom: 15px;
  //width: 150px;
}

.select select {
  display: inline-block;
  min-width: 50px;
  cursor: pointer;
  padding: 10px 5px;
  outline: 0;
  border: 0 hidden #000000;
  border-radius: 0;
  background: rgba(0, 0, 0, 0);
  color: #1c1c1c;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-family: 'Inter', serif;
  font-weight: 600;
  font-size: 15px;
}

.select select::-ms-expand {
  display: none;
}

.select select:hover,
.select select:focus {
  color: #000000;
  background: rgba(0, 0, 0, 0);
}

.select select:disabled {
  opacity: 0;
  pointer-events: none;
}

.select_arrow {
  position: absolute;
  top: 13px;
  right: 16px;
  width: 14px;
  height: 14px;
  border: solid #7b7b7b;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.select select:hover ~ .select_arrow,
.select select:focus ~ .select_arrow {
  border-color: #000000;
}

.select select:disabled ~ .select_arrow {
  border-top-color: #cccccc;
}
</style>
