<template>
  <div id="app">
    <div class="container">
      <vue-signature-pad
          id="signature"
          :width="width"
          :height="height"
          ref="signaturePad"
          :options="options"
      />
      <div class="buttons">
        <button class="styled-button btn btn-outline-primary" @click="save">Confirm</button>
        <button class="styled-button" @click="undo">Undo</button>
        <button class="styled-button" @click="clear">Clear</button>
      </div>
    </div>
  </div>
</template>

<script>
import { VueSignaturePad } from 'vue-signature-pad';

export default {
  name: "VueSignature",
  components: {
    VueSignaturePad
  },
  props: {
    width: {
      type: [String, Number],
      default: '100%'
    },
    height: {
      type: [String, Number],
      default: '200px'
    },
    initialColor: {
      type: String,
      default: "black"
    }
  },
  data() {
    return {
      options: {
        penColor: this.initialColor
      }
    };
  },
  methods: {
    undo() {
      if (this.$refs.signaturePad) {
        this.$refs.signaturePad.undoSignature();
      }
    },
    clear() {
      if (this.$refs.signaturePad) {
        this.$refs.signaturePad.clearSignature();
      }
    },
    save() {
      if (this.$refs.signaturePad) {
        const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
        if (!isEmpty) {
          this.$emit(`setSignature`,data);
        }
      }
    },
  }
};
</script>

<style scoped>
#signature {
  border: double 3px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white),
  radial-gradient(circle at top left, #4bc5e8, #9f6274);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.container {
  width: 100%;
  padding: 8px 16px;
}

.buttons {
  display: flex;
  gap: 8px;
  justify-content: center;
  margin-top: 8px;
}
.styled-button {
  border: none;
  margin-bottom: 30px;
  font-size: 15px;
  font-family: Poppins-Medium;
  color: black;
  line-height: 1.2;
  text-transform: uppercase;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100px;
  height: 40px;
  border-radius: 25px;

  background: #e6e6e6;
  background: -webkit-linear-gradient(bottom, #57b846, #d4d4d4);
  background: -o-linear-gradient(bottom, #57b846, #d4d4d4);
  background: -moz-linear-gradient(bottom, #57b846, #d4d4d4);
  background: linear-gradient(bottom, #57b846, #d4d4d4);

  position: relative;
  z-index: 1;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}
</style>
