import {Storage} from "@/services/Storage";
import axios from "axios";
import {responses} from "@/utils/responses";
import router from "@/router";

export default {
    name: 'kazang',
    state: {
        kazangSessionID: null,
        kazangProductList: [],
    },
    getters: {
        getKazangSessionID: (state) => state.kazangSessionID,
        getKazangProviders: (state) => state.kazangProductList.map(item => item.Provider),
        getKazangProductListByProvider: (state) => (provider) => {
            return state.kazangProductList
                .find(item => item.Provider === provider)
        }
    },
    mutations: {
        setKazangTimeStampProperty: (state, payload) => state.kazangTimeStamp = payload,
        setKazangSession: async (state, data) => {
            if (data) {
                await Storage.setKazangSession(data);
            } else {
                data = await Storage.getKazangSession();
            }
            state.kazangSessionID = data
        },

        clearKazangSession: async (state) => {
            await Storage.setKazangSession(null);
            state.kazangSessionID = null;
        },

        setKazangProductList: async (state, data) => {
            if (data) {
                await Storage.setKazangProductList(data);
            } else {
                data = await Storage.getKazangProductList();
            }
            state.kazangProductList = data
        },
    },
    actions: {
        kazangLogin: async (context) => {
            let store = await Storage.getDefaultStore();
            return axios.post(kazangApi + `login/${store.StoreDB}`)
                .then(({data}) => context.commit(`setKazangSession`, data.sessionId))
                .catch(() => context.commit(`setHasKazang`, false));
        },
        kazangProductList: async (context) => {
            let store = await Storage.getDefaultStore();
            return axios.get(kazangApi + `products/${store.StoreDB}`)
                .then(({data}) => context.commit(`setKazangProductList`, data.productList))
                .catch((err) => errorHandler.tomcatError(err))
        },

        // Initial step for handling Kazang payments
        firstKazangPaymentStep: async ({ dispatch, getters }, payload) => {
            let store = await Storage.getDefaultStore();
            let saleDetails = getters.getSaleDetails;
            let amount = Number(saleDetails.total) - Number(getters.getSaleDiscount.total);

            if (typeof payload === 'object') {
                return dispatch('sendPayment', { payload, store, amount });
            }
            payload = { customerContact: payload }; // Adjust payload for simple payment scenario
            return dispatch('sendSimplePayment', { payload, store, amount });
        },

        // Sends a payment request with payload details
        sendPayment: ({ dispatch, getters }, { payload, store, amount }) => {
            return axios.post(`${kazangApi}pay/${store.StoreDB}`, {
                session: getters.getKazangSessionID,
                amount: Number(payload.splitArray.Kazang.toFixed(2)),
                cell: payload.customerContact
            }).then(({ data }) => dispatch('handlePaymentResponse', { data, amount, payload }))
                .catch(err => dispatch('handlePaymentError', err));
        },

        // Sends a simpler payment request when payload is not an object
        sendSimplePayment: ({ dispatch, getters }, { payload, store, amount }) => {
            return axios.post(`${kazangApi}pay/${store.StoreDB}`, {
                session: getters.getKazangSessionID,
                amount: Number(amount.toFixed(2)),
                cell: payload.customerContact
            }).then(({ data }) => {
                data.customerContact = payload.customerContact;
                return dispatch('handlePaymentResponse', { data, amount });
            }).catch(err => dispatch('handlePaymentError', err));
        },

        // Handles the response from payment API calls
        handlePaymentResponse: ({ dispatch }, { data, amount, payload = null }) => {
            if (!data.secondStep && data.Response === '0') {
                return dispatch('processKazangInvoice', {
                    amount,
                    splitObj: payload,
                    SecondRefrence: data.SecondRefrence
                });
            } else if (data.Response === '3' || data.Response === '41') {
                return dispatch('resetKazangUser', data.Response);
            } else if (data.Response !== '0') {
                throw new Error(data.Print);
            }
            console.log(`Attempting Step 2`);
            return dispatch('retryKazangPaymentStep', { data, payload });
        },

        // Manages retry mechanism for payment steps
        retryKazangPaymentStep: ({ dispatch }, { data, payload }) => {
            let attemptCount = 0;
            const maxAttempts = 35;
            let lastError = null;

            return new Promise((resolve, reject) => {
                const attemptKazangStep = () => {
                    attemptCount++;
                    console.log(`Attempt ${attemptCount} of ${maxAttempts}`);

                    if (attemptCount > maxAttempts) {
                        console.error('Max attempts reached. Stopping retries.');
                        reject(lastError || 'Max attempts reached without specific error message.');
                        return;
                    }

                    console.log({payload})
                    return dispatch('secondKazangStep', { data, payload })
                        .then(response => {
                            if (response.success) {

                                resolve(response);  // Resolve on successful response
                                return;
                            } else {
                                // Log and prepare for another retry
                                lastError = response.message || 'Non-successful response.';
                                console.log(`Retry due to error: ${lastError}`);
                                setTimeout(attemptKazangStep, 3000);  // Retry after a delay
                            }
                        })
                        .catch(error => {
                            // Even on errors, prepare for another retry unless max attempts are reached
                            lastError = error.toString();
                            console.error(`Dispatch error on attempt ${attemptCount}: ${lastError}`);
                            setTimeout(attemptKazangStep, 3000);
                        });
                };

                attemptKazangStep();  // Initiate the first attempt
            });
        },

        // Handles any payment errors
        handlePaymentError: ({ commit }, err) => {
            commit('clearKazangSession');
            errorHandler.tomcatError(err, true);
            return Promise.reject(err); // Ensuring errors are propagated back if necessary
        },

        resetKazangUser: (context, responseCode) => {
            return Swal.fire({
                title: 'Account Update',
                html: `<input type="number" id="login" style="width: 70%" class="swal2-input" placeholder="Kazang Username">
                       <input type="password" id="password" style="width: 70%" class="swal2-input" placeholder="Kazang Password">`,
                confirmButtonText: 'Sign in',
                focusConfirm: false,
                preConfirm: async () => {
                    const login = Swal.getPopup().querySelector('#login').value
                    const password = Swal.getPopup().querySelector('#password').value
                    if (!login || !password) {
                        return Swal.showValidationMessage(`Please enter login and password`)
                    }
                    let obj = {
                        username: login,
                        password: password
                    };
                    let store = await Storage.getDefaultStore();
                    return axios.post(franchiseTomcatApi + `SetKazangUser/${store.StoreDB}`, obj)
                        .then(async () => await Storage.setAdminUser(obj))
                        .catch(err => Swal.showValidationMessage(errorHandler.tomcatError(err, true)))
                }
            }).then(res => {
                if (res.isConfirmed) {
                    let message = responseCode === `3` ? `Account Updated , Please try again` :
                        `Account Updated , Try again in 30 min`;
                    return Swal.fire(
                        `Success!`,
                        message,
                        `success`
                    ).finally(() => {
                        throw new Error(message)
                    });
                }
            });
        },

        firstSpennPaymentStep: async (context, payload) => {
            let store = await Storage.getDefaultStore();
            let saleDetails = context.getters.getSaleDetails;
            let amount = Number(saleDetails.total) - Number(context.getters.getSaleDiscount.total);

            if (typeof payload === 'object') {
                return axios.post(kazangApi + `pay/${store.StoreDB}/spennPayment`, {
                    session: context.getters.getKazangSessionID,
                    amount: Number(payload.splitArray.Kazang.toFixed(2)),
                    cell: payload.customerContact
                }).then(({data}) => {
                    if (!data.secondStep && data.Response === '0') {
                        return context.dispatch('processKazangInvoice', {
                            amount: amount,
                            splitObj: payload,
                            SecondRefrence: data.SecondRefrence
                        })
                    } else if (data.Response === '3') {
                        return context.dispatch(`resetKazangUser`, data.Response);
                    } else if (data.Response === '41') {
                        return context.dispatch(`resetKazangUser`, data.Response);
                    } else if (data.Response !== '0') {
                        throw new Error(data.Print)
                    } else {
                        data.customerContact = payload.customerContact;
                        data.splitObj = payload;
                        context.commit('setKazangSession', data.newSession)
                        return data;
                    }
                }).catch((err) => {
                    context.commit('clearKazangSession')
                    errorHandler.tomcatError(err)
                })
            }

            return axios.post(kazangApi + `pay/${store.StoreDB}/spennPayment`, {
                session: context.getters.getKazangSessionID,
                amount: Number(amount.toFixed(2)),
                cell: payload
            }).then(({data}) => {
                if (!data.secondStep && data.Response === '0') {
                    return context.dispatch('processKazangInvoice', {
                        amount: amount,
                        splitObj: null,
                        SecondRefrence: data.SecondRefrence
                    })
                } else if (data.Response === '3') {
                    return context.dispatch(`resetKazangUser`, data.Response);
                } else if (data.Response === '41') {
                    return context.dispatch(`resetKazangUser`, data.Response);
                } else if (data.Response !== '0') {
                    throw new Error(data.Print)
                } else {
                    data.customerContact = payload;
                    context.commit('setKazangSession', data.newSession)
                    return data;
                }
            }).catch((err) => {
                context.commit('clearKazangSession')
                errorHandler.tomcatError(err, true)
            })
        },

        secondSpennStep: async (context, firstStepResponse) => {
            let store = await Storage.getDefaultStore();
            return axios.post(kazangApi + `pay/${store.StoreDB}/spennApprove`, JSON.parse(firstStepResponse.SecondStepPayload))
                .then(({data}) => {
                    if (!data.Error) {
                        if (!data.approved) return {Error: 'Not Confirmed'};
                        return context.dispatch('processKazangInvoice', {
                            amount: firstStepResponse.amount,
                            splitObj: firstStepResponse.splitObj ? firstStepResponse.splitObj : null,
                            SecondRefrence: data.SecondRefrence
                        })
                    }
                    return data
                }).catch((err) => {
                    context.commit('clearKazangSession')
                    errorHandler.tomcatError(err, true)
                })
        },


        secondKazangStep: async (context, firstStepResponse) => {
            let splitObj = firstStepResponse.payload;
            firstStepResponse = firstStepResponse.data
            let store = await Storage.getDefaultStore();
            let network = await context.dispatch('checkCustomerNetwork', firstStepResponse.customerContact);
            let route = ''

            switch (network) {
                case 'MTN':
                    route = '/mtnApprove';
                    break
                case 'Airtel':
                    route = '/airtelApprove';
                    break
                default:
                    route = '/zamtelApprove'
                    break
            }
            let url = kazangApi + `pay/${store.StoreDB}${route}`;
            return axios.post(url, JSON.parse(firstStepResponse.SecondStepPayload))
                .then(({data}) => {
                    if (!data.Error) {
                        if (!data.approved) {
                            return {
                                Error: 'Not Confirmed'
                            }
                        }
                        return context.dispatch('processKazangInvoice', {
                            amount: firstStepResponse.amount,
                            splitObj: splitObj ? splitObj : null,
                            SecondRefrence: data.SecondRefrence
                        })
                    }
                    if (!data.approved) {
                        if (data.Print) {
                            return {
                                Error: data.Print
                            }
                        }
                    }
                    return data
                }).catch((err) => {
                    context.commit('clearKazangSession')
                    errorHandler.tomcatError(err)
                })

        },

        processKazangInvoice: (context, response) => {
            if (response.splitObj) {
                response.splitObj.SecondRefrence = response.SecondRefrence;
                return context.dispatch('processTransaction', response.splitObj)
                    .then(() => {return {success: true}})
            }
            return context.dispatch('processTransaction', {
                refund: false,
                total: Number(response.amount.toFixed(2)),
                paid: Number(response.amount.toFixed(2)),
                SecondRefrence: response.SecondRefrence,
                change: 0,
                type: "Kazang"
            }).then(() => {return {success: true}})
        },

        checkCustomerNetwork(context, phoneNumber) {
            let prefix = phoneNumber.slice(0, 3);
            switch (prefix) {
                case '096':
                case '076':
                    return 'MTN';
                case '097':
                case '077':
                    return 'Airtel'
                default :
                    return 'Zamtel';
            }
        },

        requestPasswordReset: async (context, username) => {
            return axios.post(franchiseTomcatApi + `SetKazangUser/${username}/forgotPasswordRequest`, {
                username: username
            }).catch(err => errorHandler.tomcatError(err))
        },

        resetPassword: async (context, payload) => {
            return axios.post(franchiseTomcatApi + `SetKazangUser/${payload.username}/resetPassword`, payload)
                .then(() => router.push({name: 'login'}))
                .catch(err => errorHandler.tomcatError(err))
        },

    }
}
