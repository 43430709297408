<template>
  <full-page title="Lyt Soft Apps">
    <!-- Bootstrap Modals for each image -->
    <div v-for="image in images" :key="`modal-${image.id}`" class="modal fade" :id="`exampleModal${image.id}`" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg"> <!-- Set modal-dialog to modal-lg for a wider modal -->
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ image.alt }}</h5>
            <button type="button" class="btn-close" @click="closeModal(image.id)" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="image-grid">
              <div class="card">
                <div class="d-flex justify-content-center">
                  <img :src="`${appUrl}${image.filename}`" :alt="image.alt" style="max-width: 100%" height="300px">
                </div>
              </div>
              <div class="card">
                <div class="qr-code text-center">
                  <qriously :value="getQRCodeValue(image.alt)" :size="150"></qriously>
                </div>
              </div>
            </div>
            <p v-html="image.description"> <!-- Use v-html to render HTML content from image.description -->
              <span class="text-muted">Click the <strong>Visit Site</strong> button or scan QR code to visit site.</span>
            </p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeModal(image.id)">Close</button>
            <a :href="getWebsiteLink(image.alt)" target="_blank" class="btn btn-primary">Visit Site</a>
            <!-- "Visit Site" button opens the respective website in a new tab -->
          </div>
        </div>
      </div>
    </div>

    <!-- Image Grid -->
    <div class="image-grid-container">
      <div class="image-grid">
        <div class="image-item" v-for="image in images" :key="image.id">
          <div class="card" @click="openModal(image.id)"
               @mouseover="hoverIndex = image.id"
               @mouseleave="hoverIndex = null"
               :class="{ 'pointer': hoverIndex === image.id }">
            <div class="image-wrapper">
              <img :src="`${appUrl}${image.filename}`" :alt="image.alt">
            </div>
            <div class="card-body">
              <h5 class="card-title">{{ image.alt }}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </full-page>
</template>

<script>
import FullPage from "@/views/point-of-sale/tables/FullPage";
import CustomModal from "@/views/point-of-sale/tables/CustomModal";

export default {
  name: "TicketCreation",
  components: {
    CustomModal,
    FullPage,
  },
  data() {
    return {
      images: [
        {id: 1,
          filename: 'kazang.jpeg',
          alt: 'Kazang App',
          description: 'This is the Kazang App.'},
        {
          id: 2,
          filename: 'GateEntry.png',
          alt: 'Gate Entry App',
          description: 'The Gate Entry app is used in warehouses and businesses where stock is received through a specific process. It\'s installed on devices at these locations. The app verifies incoming stock against purchase orders to ensure accuracy. If there are items not listed on the order, it prompts the user to reject them. If there\'s a quantity discrepancy, it prompts the user to verify and records any differences. Overall, the app aims to ensure received stock is correct and in good condition.'
        },
        {id: 3,
          filename: 'dsr.jpg',
          alt: 'DSR App',
          description: 'The DSR App is designed for sales representatives to perform their daily sales activities. It helps record sales orders, and generate sales quotations, ensuring efficient and organized sales operations.'},
        {id: 4,
          filename: 'Franchise.jpg',
          alt: 'Franchise App',
          description: 'The Analytics Platform provides real-time business insights, allowing users to monitor key performance indicators, sales data, and inventory levels. ' +
              'It offers detailed reports and analytics to support informed decision-making and enhance business performance.'},
        {id: 5,
          filename: 'supervisor-app.jpg',
          alt: 'Supervisor App',
          description: 'This is the Supervisor App.'},
        {
          id: 6,
          filename: 'Franchise1.jpg',
          alt: 'Mobile Analytics App',
          description: '<p>The Mobile Analytics App gives you instant business information and statistics on your phone. You can see how well your business is doing, like sales and how much stock you have left. It helps you make quick decisions by showing clear reports and lets you manage your business better.</p>'
        },
      ],
      hoverIndex: null // Track hovered image index
    };
  },
  computed: {
    appUrl() {
      return '/img/AppImages/';
    }
  },
  methods: {
    openModal(id) {
      const modalId = `exampleModal${id}`;
      const modalElement = document.getElementById(modalId);
      if (modalElement) {
        modalElement.classList.add('show');
        modalElement.style.display = 'block';
      }
    },
    closeModal(id) {
      const modalId = `exampleModal${id}`;
      const modalElement = document.getElementById(modalId);
      if (modalElement) {
        modalElement.classList.remove('show');
        modalElement.style.display = 'none';
      }
    },
    getQRCodeValue(appName) {
      switch (appName) {
        case 'Kazang App':
          return 'https://zambia-store.kazang.net/home.php';
        case 'Gate Entry App':
          return 'https://gate-entry.abc.co.zm/login';
        case 'DSR App':
          return 'https://dsr.abc.co.zm/login';
        case 'Franchise App':
          return 'https://chrilantech.com/Franchise';
        case 'Supervisor App':
          return 'https://supervisor.abc.co.zm/login';
        case 'Mobile Analytics App':
          return 'http://mobile.chrilantech.com';
        default:
          return '';
      }
    },
    getWebsiteLink(appName) {
      return this.getQRCodeValue(appName); // Directly return the website link based on app name
    }
  }
};
</script>

<style lang="scss" scoped>
.image-grid-container {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  padding: 0 1rem; /* Add some padding for small screens */
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* More flexible grid */
  gap: 1rem;
  max-width: 1200px;
  width: 100%;
}

.image-item {
  display: flex;
  justify-content: center;
}

.card {
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}

.card:hover,
.card.pointer {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.image-wrapper {
  width: 100%;
  padding-top: 100%; /* 1:1 Aspect Ratio */
  position: relative;
}

.image-wrapper img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.card-body {
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  text-align: center;
}

.card-title {
  margin: 0;
  font-size: 1.25rem;
  color: #333;
}

.qr-code {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Ensure QR code container takes full height */
}

.qr-code canvas {
  width: 150px; /* Adjust as needed */
  height: 150px; /* Adjust as needed */
}

/* Ensure modal image scales properly */
.modal-body img {
  max-width: 100%; /* Ensure the image scales down */
  height: auto; /* Maintain aspect ratio */
}

@media (max-width: 768px) {
  .modal-dialog {
    max-width: 90vw; /* Make modal responsive */
  }
}

</style>
