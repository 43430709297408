<template>
  <div class="container-fluid current-sale-line">
    <div class="row">
      <div class="line-description-pane col-7">
        <h6 class="line-description">
          {{ $utils.toPascalCase($utils.cutLength(saleLine.Description, 30)) }}<br>
        </h6>
        <button @click="voidItem(saleLine)">
          <font-awesome-icon icon="fa-solid fa-trash" style="color: red"/>
        </button>
        <h5 class="text-success" v-if="saleLine.Discount>0">{{ `Discount ${$utils.formatMoney(saleLine.Discount)}` }}</h5>
      </div>
      <div class="d-flex flex-column justify-content-between line-prices-pane col-5">
        <CurrentSaleQty :saleLine="saleLine"/>
        <div class="price-row">
          <p class="price-label">Item Price: <span
              class="line-price">{{ $utils.formatMoney(saleLine.Unit, 2, '.', ',', false) }}</span></p>
          <p class="price-label">Total: <span
              class="line-price">{{ $utils.formatMoney(saleLine.Unit * saleLine.Qty, 2, '.', ',', false) }}</span>
          </p>
        </div>
      </div>
    </div>
    <div class="row" v-if="hasInstructions">
      <ul class="cooking-instruction-list">
        <li class="cooking-instruction-list-item" v-for="instruction in instructions">
          {{ instruction.CookingMsg }} <span>{{ instruction.count }} X {{
            $utils.formatMoney(instruction.Price)
          }}</span>
          Total: {{ $utils.formatMoney(instruction.count * instruction.Price) }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import CurrentSaleQty from "@/components/point-of-sale/sales/CurrentSaleQty";

export default {
  name: "CurrentSaleLine",
  components: {CurrentSaleQty},
  props: {
    saleLine: {
      type: Object,
      required: true
    }
  },
  computed: {
    hasInstructions() {
      return (this.saleLine.instructions && this.saleLine.instructions.length > 0)
    },
    instructionTotal() {
      if (!this.saleLine.instructions || this.saleLine.instructions.length === 0) return 0.00;
      let total = this.saleLine.instructions.map(i => i.Price).reduce((acc, n) => Number(n) + Number(acc))
      if (isNaN(total)) {
        return 0.00;
      }
      return Number(total.toFixed(2))
    },
    instructions() {
      return this.saleLine.instructions.map((int) => {
        int.Price = Number(int.Price.toFixed(2))
        return int;
      });
    }
  },
  methods: {
    voidItem(item) {
      this.$store.commit('voidLine', item)
    }
  }
}
</script>

<style lang="scss" scoped>
.current-sale-line {
  padding: 10px;
  min-height: 95px;
  margin-bottom: 5px;

  .row {
    .line-description-pane {
      .line-description {
        font-size: 18px;
        margin: 0;
        line-height: 18px;
        font-weight: 500;
      }
    }

    .line-prices-pane {
      .price-label {
        font-size: 12px;
        margin: 0;

        .line-price {
          font-size: 15px;
          font-weight: 700;
        }
      }
    }
  }
}

button{
  background-color: transparent;
}

.cooking-instruction-list {
  list-style: inside;

  .cooking-instruction-list-item {
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
    color: #404040;
    display: flex;
    justify-content: space-between;
  }
}

.price-row {
  display: flex;
  flex-direction: row;
}
</style>
