import TimeManager from "@/services/TimeManager";
import { errorHandler } from "@/services/errorHandler";
import axios from "axios";

export default {
    name: "tickets",
    state: {

    },
    getters: {

    },
    mutations: {
    },
    actions: {
        getTicketDetails:(context , id)=> {
            return axios.get(ticketApi+`ticket/getDetails?id=`+id)
                .then(({data})=>data).catch(err=>errorHandler.tomcatError(err))
        },
        getTicketById:(context , id)=> {
            return axios.get(ticketApi+`ticket/getByID?id=`+id)
                .then(({data})=>data).catch(err=>errorHandler.tomcatError(err))
        },
    }
}
