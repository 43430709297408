<template>
  <div>
    <div class="scroll-container">
      <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
        <li class="nav-item" role="presentation">
          <button :class="`nav-link ${activeTab===1?`active`:``}  btn-outline-info`" data-bs-toggle="pill"
                  type="button" role="tab" @click="setTimeFrame(1)"
                  aria-selected="true">
            <b class="text-white">This Year</b>
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button :class="`nav-link ${activeTab===2?`active`:``}  btn-outline-info`" id="month-tab"
                  data-bs-toggle="pill"
                  type="button" role="tab" @click="setTimeFrame(2)"
                  aria-selected="false">
            <b class="text-white">This Month</b>
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button :class="`nav-link ${activeTab===6?`active`:``}  btn-outline-info`" id="last-month-tab"
                  data-bs-toggle="pill"
                  type="button" role="tab" @click="setTimeFrame(6)"
                  aria-selected="false">
            <b class="text-white">Last Month</b>
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button :class="`nav-link ${activeTab===3?`active`:``}  btn-outline-info`" id="week-tab"
                  data-bs-toggle="pill"
                  type="button" role="tab" @click="setTimeFrame(3)"
                  aria-selected="false">
            <b class="text-white">Last 7 Days</b>
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button :class="`nav-link ${activeTab===7?`active`:``}  btn-outline-info`" id="yesterday-tab"
                  data-bs-toggle="pill"
                  type="button" role="tab" @click="setTimeFrame(7)"
                  aria-selected="false">
            <b class="text-white">Yesterday</b>
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button :class="`nav-link ${activeTab===4?`active`:``}  btn-outline-info`" id="today-tab"
                  data-bs-toggle="pill"
                  type="button" role="tab" @click="setTimeFrame(4)"
                  aria-selected="false">
            <b class="text-white">Today</b>
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button :class="`nav-link ${activeTab===5?`active`:``}  btn-outline-info`" id="custom-tab"
                  data-bs-toggle="pill"
                  type="button" role="tab" @click="$store.commit('setActiveTab',5)"
                  aria-selected="false">
            <b class="text-white">Custom</b>
          </button>
        </li>
      </ul>
    </div>
    <div class="row float-end mx-2">
      <div v-if="activeTab === 5">
        <label>
          Start Date
          <input type="date" class="form-control" required v-model="range.from">
        </label>

        <label class="m-3">
          End Date
          <input type="date" class="form-control" required v-model="range.to">
        </label>
        <button type="submit" class="btn btn-primary" @click="setTimeFrame(5)">Select</button>
      </div>
    </div>
  </div>
</template>

<script>
import TimeManager from "@/services/TimeManager";

export default {
  name: "RangeSelector",
  data() {
    return {
      dateTimeManager: TimeManager,
      range: this.$store.getters.getGlobalDateRange
    }
  },
  computed: {
    activeTab() {
      return this.$store.getters.getActiveTab;
    },
  },
  props: {
    submitMethod: {type: Function, required: true},
  },
  methods: {
    setTimeFrame(type = 2) {
      this.$utils.showLoading()
      this.$store.commit('setActiveTab', type);
      switch (type) {
        case 1:
          this.$store.commit('setGlobalDateRange', this.dateTimeManager.getThisYear())
          break;

        case 2:
          this.$store.commit('setGlobalDateRange', this.dateTimeManager.getThisMonth())
          break;
        case 3:
          this.$store.commit('setGlobalDateRange', this.dateTimeManager.getLastWeek())
          break;
        case 4:
          this.$store.commit('setGlobalDateRange', this.dateTimeManager.getToday())
          break;

        case 5:
          if (!this.range.from || !this.range.to) {
            return responses.showWarning(`Invalid Time Frame`)
          }
          this.$store.commit('setGlobalDateRange', this.range)
          break;

        case 6:
          this.$store.commit('setGlobalDateRange', this.dateTimeManager.getPreviousMonth())
          break;

        case 7:
          this.$store.commit('setGlobalDateRange', this.dateTimeManager.getYesterday())
          break;
      }
      return Promise.all([
        this.submitMethod()
      ]).finally(() => this.$utils.hideLoading());
    },
  }
}
</script>

<style lang="scss" scoped>
.scroll-container {
  overflow-x: auto;
  white-space: nowrap;
  padding: 0 10px;
}

.nav-pills {
  display: flex;
  flex-wrap: nowrap;
}

.nav-link {
  background: #1d90dd !important;
  border-radius: 15px;
  margin: 0 2px;
}

.active {
  background: #0B2550 !important;
}

li {
  display: inline-block;
  margin: 2px;
}
</style>
