<template>
  <vue-bottom-sheet :background-clickable="false"
                    :background-scrollable="false"
                    :click-to-close="true"
                    :is-full-screen="true"
                    :maxHeight="'100vh'"
                    :maxWidth="'95%'"
                    :overlay="true"
                    :rounded="true"
                    :swipe-able="true"
                    ref="cashupSheet">
    <CashupSummary :cashup="cashup"/>
  </vue-bottom-sheet>
</template>

<script>
import VueBottomSheet from "@webzlodimir/vue-bottom-sheet";
import CashupSummary from "@/components/point-of-sale/cashup/CashupSummary";

export default {
  name: "FinalizeCashupSheet",
  components: {CashupSummary, VueBottomSheet},
  props: {
    cashup: {type: Object, required: true}
  },
  methods: {
    open() {
      try {
        this.$refs.cashupSheet.open();
      } catch (e) {
        console.error(e)
      }
    },
  }
}
</script>
