import axios from "axios";
import {Storage} from "@/services/Storage";
import {responses} from "@/utils/responses";
import store from "@/store";
import router from "@/router";

let getNow = function () {
    let date = new Date();
    let d = date.toISOString().split('T')[0]
    let t = date.toISOString().split('T')[1].split('.')[0];
    return d + ` ` + t;
}
const isEmpty = function (obj) {
    if (!obj) {
        return true;
    }
    return Object.keys(obj).length === 0;
}
let validateSecurity = async function (security) {
    return store.dispatch(`validateSecurityPermission`,security);
}
export default {
    name: 'sales',
    state: {
        currentSale: 0,
        saleDiscount: {},
        currentSaleLines: [],
        receipts: [],
        latestInvoice: {},
        refundInv: undefined,
        tip: 0,
        saleDetails: {}, customer: '',
        discountSpecials: [],
        comboSpecials: [],
        multiSpecials: [],
    },
    getters: {
        getLatestInvoice: (state) => state.latestInvoice,
        getRefundInvoice: (state) => state.refundInv,
        getCustomer: (state) => state.customer,
        getCurrentSale: (state) => state.currentSale,
        getSaleLines: (state) => state.currentSaleLines,
        getTipAmount: (state) => state.tip,
        getDiscountSpecials: (state) => state.discountSpecials,
        getComboSpecials: (state) => state.comboSpecials,
        getMultiSpecials: (state) => state.multiSpecials,
        getSaleTotal: (state) => {
            let saleLines = state.currentSaleLines;
            if (!saleLines || saleLines.length === 0) return 0.00;
            return saleLines.map(line => {
                let total = line.Qty * line.Unit;
                let instructions = line.instructions;
                if (instructions && instructions.length > 0) {
                    let instructionTotal = instructions.map(i => i.Price).reduce((acc, n) => Number(n) + Number(acc))
                    total += Number(instructionTotal);
                }
                return Number(total);
            }).reduce((sum, line) => Number(sum) + Number(line));
        },
        getSaleDiscount: state => {
            if (!isEmpty(state.saleDiscount)) {
                return state.saleDiscount
            } else return {
                type: 'none', value: 0, total: 0
            }
        },
        getSaleUnits: state => state.currentSaleLines.length,
        getSaleVat: (state, getters) => {
            return getters.getSaleTotal * 0.16;
        },
        getSubTotal: (state, getters) => {
            return getters.getSaleTotal - getters.getSaleVat
        },
        getSaleDetails: (state) => state.saleDetails,
        getItemSpecialPrice: (state) => (itemPLU) => {
            let specialPrice = null;

            //Discount Special
            let special = state.discountSpecials.find(sp => sp.Plu === itemPLU)
            if (special) {
                specialPrice = special.SpePrice * 1;
            }

            //Combo Special
            if (!special) {
                special = state.comboSpecials.find(sp => sp.plu === itemPLU);
                if (special) {
                    let specialID = special.special_ID;
                    let groupID = Number(special.group);
                    let otherGroup = groupID === 1 ? 2 : 1;

                    let currentSaleLines = state.currentSaleLines;
                    let otherItems = state.comboSpecials.filter(special => special.special_ID === specialID);
                    otherItems = otherItems.filter(item => Number(item.group) === otherGroup)

                    let commonItems = currentSaleLines.filter(saleLineItem =>
                        otherItems.find(otherItem => otherItem.plu === saleLineItem.Plu)
                    );

                    if (commonItems.length > 0) {
                        state.currentSaleLines = state.currentSaleLines.map(line => {
                            for (let i = 0; i < commonItems.length; i++) {
                                let otherItem = commonItems[i];
                                if (line.index === otherItem.index) {
                                    let otherItemSpecial = otherItems.find(otherItem => otherItem.plu === line.Plu)
                                    if (otherItemSpecial) {
                                        line.Unit = otherItemSpecial.Price;
                                        line.Line = otherItemSpecial.Price * line.Qty - line.Discount;
                                    }
                                }
                            }
                            return line;
                        })
                        specialPrice = special.Price;
                    }
                }
            }

            //Multi-Quantity Special
            if (!special) {
                special = state.multiSpecials.find(sp => sp.Plu === itemPLU);
                if (special) {
                    let quantity = Number(special.HowMany)
                    let price = Number(special.Price)

                    let currentSaleLines = state.currentSaleLines;
                    let saleLines = currentSaleLines.filter(line => line.Plu === itemPLU)
                        .map(saleLine => {
                            let qty = saleLine.Qty;
                            if ((qty + 1) >= quantity) {
                                specialPrice = saleLine.Unit = price;
                                saleLine.Line = price * qty;
                            }
                            return saleLine;
                        });
                    state.currentSaleLines = state.currentSaleLines.map(line => {
                        for (let i = 0; i < saleLines.length; i++) {
                            let saleLine = saleLines[i];
                            if (line.index === saleLine.index) {
                                line.Unit = saleLine.Unit;
                                line.Line = saleLine.Line;
                            }
                        }
                        return line;
                    });
                }
            }
            return specialPrice;
        }
    },
    mutations: {
        setDiscountSpecials: async (state, payload) => {
            if (payload) await Storage.setDiscountSpecials(payload)
            else payload = await Storage.getDiscountSpecials()
            state.discountSpecials = payload;
        },
        setMultiSpecials: async (state, payload) => {
            if (payload) await Storage.setMultiSpecials(payload)
            else payload = await Storage.getMultiSpecials()
            state.multiSpecials = payload;
        },
        setComboSpecials: async (state, payload) => {
            if (payload) await Storage.setComboSpecials(payload)
            else payload = await Storage.getComboSpecials()
            state.comboSpecials = payload;
        },
        setCurrentSale: (state, data) => state.currentSale = data,
        setSaleDetails: (state, data) => state.saleDetails = data,
        setRefundInvoice: (state, data) => state.refundInv = data,
        setCurrentLines: (state, data) => state.currentSaleLines = data,
        setCustomer: (state, data) => state.customer = data,
        addToCurrentSaleLines: (state, payload) => {
            let data = payload;
            let item = state.currentSaleLines.find(line => {
                return line.Plu === data.Plu &&
                    line.Unit === data.Unit &&
                    line.Description === data.Description;
            });
            if (item) {
                item.Qty = Number(item.Qty) + Number(payload.Qty);
                item.Line = Number(payload.Unit * item.Qty).toFixed(2);
                const data = [...payload.instructions, ...item.instructions];
                let edited = data.reduce((accumulator, msg) => {
                    if (accumulator.has(msg.CookingNum)) {
                        let m = accumulator.get(msg.CookingNum)
                        m.count += msg.count
                    } else {
                        accumulator.set(msg.CookingNum, msg);
                    }
                    return accumulator;
                }, new Map())
                item.instructions = [...edited.values()]
            } else {
                data.index = state.currentSaleLines.length + 1
                state.currentSaleLines.push(data);
            }
        },
        voidItem: async (state, payload) => {
            let validation = await validateSecurity(`Void`);
            if (!validation) return;
            let item = state.currentSaleLines.find(s => s.index === payload.index)
            item.Qty--;
            item.instructions = item.instructions.map((s) => {
                let msg = payload.instructions.find(m => m.CookingNum === s.CookingNum)
                if (msg) {
                    s.count -= msg.count
                }
                return s;
            });
            if (item.Qty <= 0) {
                let arr = state.currentSaleLines
                state.currentSaleLines = $utils.removeIf(arr, s => s.index !== payload.index)
            }
            await store.dispatch(`calculateTipAmount`)
            console.log(`Voided`)
        },

        voidLine:async (state, data) => {
            let validation = await validateSecurity(`Void`);
            if (!validation) return;
            let arr = state.currentSaleLines
            state.currentSaleLines = $utils.removeIf(arr, s => {
                return s.index !== data.index;
            })
            await store.dispatch(`calculateTipAmount`)
            console.log(`Voided`)
        },
        addReceipt: (state, payload) => {
            state.latestInvoice = payload
            state.receipts.push(payload)
            state.currentSaleLines = []
            state.saleDiscount = 0.00
        },
        applyDiscount: (state, payload) => state.saleDiscount = payload,
        clearSale: (state) => {
            state.currentSaleLines = [];
            state.currentSale = 0;
            state.saleDiscount = undefined;
            state.tip = 0;
        },
        clearDiscount: (state) => state.saleDiscount = undefined,
        setTipAmount: (state , data) => state.tip = data,
    },
    actions: {
        calculateTipAmount: async (context) => {
            let settings = await Storage.getDeviceSettings();
            let saleTotal = Number(context.getters.getSaleTotal);
            if (settings && settings.AutoTip > 0){
                let tip = Number(settings.AutoTip);
                tip = (tip / 100) * saleTotal;
                context.commit(`setTipAmount` , tip)
            }
        },
        parseInvoice: async (context, payload) => {
            let user = await Storage.getCurrentUser();
            let total = Number(payload.total) - Number(context.getters.getTipAmount);
            let discountTotal = Number(context.getters.getSaleDiscount.total);
            let invoice = {
                PC: localStorage.getItem('_capuid'),
                saleNumber: context.state.currentSale,
                date: null,
                user: user.Username,
                reference: payload.reference ? payload.reference : ``,
                customer: context.getters.getCustomer,
                paymentType: payload.type,
                items: context.state.currentSaleLines,
                invoice: (!payload.refund) ? context.state.currentSale : payload.oldInv,
                discount:  discountTotal,
                vat: (!payload.refund) ? Number(context.getters.getSaleVat) : 0,
                subTotal: (!payload.refund) ? Number(context.getters.getSubTotal) : Number(total),
                total: Number(total),
                paidAmount: (!payload.refund) ? Number(payload.paid) : Number(total) + context.getters.getTipAmount,
                change: (!payload.refund) ? Number(payload.change) : 0,
                units: (!payload.refund) ? Number(context.getters.getSaleUnits) : payload.items.length,
                refund: payload.refund,
                tip: Number(context.getters.getTipAmount),
            };

            if (payload.type === 'Split') {
                invoice.split = [
                    {
                        method: 'cash',
                        amount: payload.splitArray.cash
                    }, {
                        method: 'card',
                        amount: payload.splitArray.card
                    },

                    {
                        method: 'Kazang',
                        amount: payload.splitArray.Kazang
                    },

                    {
                        method: 'Spenn',
                        amount: payload.splitArray.Spenn
                    },
                ];
                for (let i = 0; i < payload.splitArray.other.length; i++) {
                    let method = payload.splitArray.other[i];
                    invoice.split.push({
                        method: method.name,
                        amount: Number(method.amount)
                    });
                }
            }

            if ((payload.type === 'Kazang' || (payload.type === 'Split' && payload.splitArray.Kazang > 0)) ||
                (payload.type === 'Spenn' || (payload.type === 'Split' && payload.splitArray.Spenn > 0))) {
                invoice.SecondRefrence = payload.SecondRefrence
            }

            if (payload.type === 'Kazang') {
                invoice.total += Number(invoice.discount);
            }
            return invoice
        },
        processTransaction: async (context, payload) => {
            let isAllowed = await validateSecurity(`convertToInvoice`);
            if (!isAllowed) return false;

            if (payload.type === `AccountSale`) isAllowed = await validateSecurity(`AccountSale`);
            if (!isAllowed) return false;

            if (payload.type === `Cash`) isAllowed = await validateSecurity(`Cash`);
            if (!isAllowed) return false;

            if (payload.type === `Card`) isAllowed = await validateSecurity(`Card`);
            if (!isAllowed) return false;

            console.log(`Processing`)

            let invoice = await context.dispatch('parseInvoice', payload)
            return context.dispatch('processInvoice', invoice).then(res=>{
                if (res) {
                    Swal.close();
                    router.push({name: 'viewPayment'})
                }
            })
        },


        processInvoice: async (context, payload) => {
            let store = await Storage.getDefaultStore();
            return axios.post(serverApi + `invoice/${store.StoreDB}/mobile?android=true`, payload)
                .then(async ({data}) => {
                    if (typeof data === 'string') {
                        let correctedData = data.replaceAll(/NaN/g, '0');
                        data = JSON.parse(correctedData);
                    }
                    if (data.status === 200) {
                        let androidResponse = JSON.parse(data.response.androidprintdata);
                        data.response.zraInvoiceQrCode = androidResponse.zraInvoiceQrCode;
                        return Promise.all([
                            context.dispatch(`BtPrint`,{ invoice : data.response.invoice , reprint : false }),
                            context.commit('setCurrentSale', 0),
                            context.commit('setTipAmount', 0),
                            context.commit('addReceipt', data.response),
                            context.commit('clearDiscount'),
                            context.commit('setSaleDetails', {}),
                            context.commit('setCurrentSale', 0),
                            context.commit('setCustomer', ""),
                        ]).then(() => true);
                    }
                }).catch((err) => errorHandler.tomcatError(err))
        },
        BtPrint: async (context, payload) => {
            let store = await Storage.getDefaultStore();
            return axios.get(franchiseTomcatApi + `invnumandi/${payload.invoice}/db/${store.StoreDB}`)
                .then(({data}) => {
                    try {
                        if (payload.reprint){
                            Printing.reprintInvoice(JSON.stringify(data))
                        }else {
                            Printing.printInvoice(JSON.stringify(data))
                        }
                        responses.showInfo(`Printing Invoice ${payload.invoice}`)
                    } catch (e) {}
                })
        },
        printBillSlip: async (context, payload) => {
            let isAllowed = await validateSecurity(`ReprintBill`);
            if (!isAllowed) return ;

            let store = await Storage.getDefaultStore();
            return axios.get(franchiseTomcatApi + `billPrint?Sale=${payload}&DB=${store.StoreDB}`)
                .then(({data}) => {
                    try {
                        Printing.printBill(JSON.stringify(data))
                        responses.showInfo(`Printing Bill ${payload}`)
                    } catch (e) {}
                })
        },
        async getAllInvoices(context, payload) {
            let store = await Storage.getDefaultStore();
            return axios.post(franchiseTomcatApi + `completedsale/${store.StoreDB}/getLast10Sales`, payload)
                .then(({data}) => data)
                .catch((err) => errorHandler.tomcatError(err))
        },

        async getInvoiceLines(context, inv) {
            let store = await Storage.getDefaultStore();
            return axios.get(franchiseTomcatApi + `completedsale/${store.StoreDB}/getInvNum`, {params: {invoice: inv}})
                .then(({data}) => {
                    context.commit('setRefundInvoice', data)
                    return data;
                }).catch((err) => errorHandler.tomcatError(err))
        },
        reprintLastInvoice: async (context) => {
            let isAllowed = await validateSecurity(`Reprint`);
            if (!isAllowed) return ;
            $utils.showLoading()
            let invoice = context.getters.getLatestInvoice.invoice;
            if (invoice) {
                return context.dispatch('BtPrint', {invoice: invoice, reprint: true})
                    .finally(()=>$utils.hideLoading());
            }
            let store = await Storage.getDefaultStore();
            return axios.get(franchiseTomcatApi + `completedsale/${store.StoreDB}/getLastInvoiceNumber`)
                .then(({data}) => context.dispatch('BtPrint', {invoice: data, reprint: true}))
                .catch((err) => errorHandler.tomcatError(err)).finally(()=>$utils.hideLoading());
        },
        openTable: async (context, tab) => {
            let isAllowed = await context.dispatch(`validateSecurityPermission`,`LoadTable`);
            if (!isAllowed) return false;
            let items = tab.items.map((item, index) => {
                item.index = index;
                return item
            })
            await context.commit('setCurrentSale', tab.saleNumber)
            await context.commit('setCurrentLines', items)
            await context.commit('setCustomer', tab.customer)
            await store.dispatch(`calculateTipAmount`)
            return true;
        },
        searchCashups : async (context , payload) => {
            let store = await Storage.getDefaultStore();
            return axios.post(franchiseTomcatApi + `cashup/${store.StoreDB}/search`,payload)
                .then(({data}) => data)
                .catch((err) => errorHandler.tomcatError(err))
        },

        getAllActiveSpecials: async (context) => {
            let store = await Storage.getDefaultStore()
            if (!store) return;
            let DB = store.StoreDB;
            return Promise.all([
                axios.get(serverApi + `specials/${DB}/getActiveSpecials?type=discount`)
                    .then(({data}) => context.commit(`setDiscountSpecials`, data)),

                axios.get(serverApi + `specials/${DB}/getActiveSpecials?type=combo`)
                    .then(({data}) => context.commit(`setComboSpecials`, data)),

                axios.get(serverApi + `specials/${DB}/getActiveSpecials?type=multi`)
                    .then(({data}) => context.commit(`setMultiSpecials`, data)),
            ])
        }

    }
}
