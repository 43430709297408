<template>
  <vue-bottom-sheet :background-clickable="false"
                    :background-scrollable="false"
                    :click-to-close="canSwipe"
                    :is-full-screen="true"
                    :maxHeight="'65%'"
                    :maxWidth="'95%'"
                    :overlay="true"
                    :rounded="true"
                    :swipe-able="canSwipe"
                    ref="methodSheet">
    <PaymentMethods :set-swipe="setSwipe" />
  </vue-bottom-sheet>
</template>

<script>
import VueBottomSheet from "@webzlodimir/vue-bottom-sheet";
import PaymentMethods from "@/components/point-of-sale/sales/payment/PaymentMethods";
export default {
  name: "MethodsBottomSheet",
  components: {PaymentMethods, VueBottomSheet},
  data(){
    return {
      canSwipe : true
    }
  },
  methods: {
    close() {
      try {
        this.$refs.methodSheet.close();
      } catch (e) {
        console.error(e);
      }
    },

    setSwipe(){
      this.canSwipe = !this.canSwipe
    },

    open() {
      try {
        this.$refs.methodSheet.open();
      } catch (e) {
        console.error(e)
      }
    },
  }
}
</script>

<style scoped>

</style>
