import {Storage} from "@/services/Storage";
import axios from "axios";
import {responses} from "@/utils/responses";
import router from "@/router";

export default {
    name: 'float',
    state: {
        floats: [],
    },
    getters: {
        getFloats: (state) => state.floats,
        getCurrentUserFloat: (state, getters) => {
            let user = getters.getCurrentUser;
            return state.floats.find(f => f.user === user.Username);
        },

        getFloatByUser: () => (user) => {
            return Storage.getFloat().then(res => {
                return res.find(s => s.user === user)
            });
        },
    },
    mutations: {
        setUserFloat: async (state, payload) => {
            await Storage.getFloat()
                .then(float => {
                    if (!float) {
                        float = [];
                    }
                    if (payload) {
                        if (!float.find(f => f.user === payload.user)) {
                            float.push(payload)
                            Storage.setFloat(float).then(() => {
                                state.float = float;
                            });
                        }
                    } else {
                        state.float = float;
                    }
                });
        },
    },
    actions: {
        getCurrentUserFloat: async (context) => {
            let store = await Storage.getDefaultStore();
            let settings = await Storage.getDeviceSettings();
            let obj = {
                device: settings.PC,
                user: context.getters.getCurrentUser.Username,
            }
            return axios.post(serverApi + `float/check/${store.StoreDB}`, obj)
                .then(({data}) => data.assigned)
                .catch((err) => errorHandler.tomcatError(err))

        },
        checkUserFloat: async (context) => {
            let user = await Storage.getCurrentUser();
            if (!user) return router.push({name: 'poslogin'});
            let assignedTo = user.Username;
            return context.dispatch('getFloatUsers').then((res) => {
                let filter = res.find(s => s.toLowerCase() === assignedTo.toLowerCase());
                if (!filter) return router.push({name: 'pos'})
                return assignedTo;
            })
        }
    }
}
