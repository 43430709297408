import {Storage} from "@/services/Storage";
import axios from "axios";
import queryStringify from "qs-stringify";

export default {
    name: 'kitchenOrders',
    state: {
        readyOrders : 0,
    },
    getters: {
        getReadyOrdersCount:(state) => state.readyOrders,
    },
    mutations: {
        setReadyOrdersCount:(state , data) => state.readyOrders = data,
    },
    actions: {
        checkReadyOrders: async (context) => {
            let store = await Storage.getDefaultStore();
            let user = await Storage.getCurrentUser();
            if (!store || !user) return;
            let DB = store.StoreDB;
            user = user.Username;
            let readyOrders = context.getters.getReadyOrdersCount;
            return axios.get(serverApi+`orders/${DB}/countReadyOrders?user=${user}`)
                .then(({data})=> {
                   context.commit(`setReadyOrdersCount`, data);
                   return data > readyOrders;
                })
        },
        getReadyOrders: async (context , payload) => {
            let store = await Storage.getDefaultStore();
            let DB = store.StoreDB;
            let user = await Storage.getCurrentUser();
            payload.user = user.Username;
            payload = `?`+queryStringify(payload)
            return axios.get(serverApi+`orders/${DB}/getReadyOrders`+payload)
                .then(({data})=>data).catch(err=>errorHandler.tomcatError(err))
        },
        getOrderLocations: async () => {
            let store = await Storage.getDefaultStore();
            let DB = store.StoreDB;
            return axios.get(serverApi+`orders/${DB}/getOrderLocations`)
                .then(({data})=>data).catch(err=>errorHandler.tomcatError(err));
        },
        getOrderItems : async (context , orderID)=>{
            let store = await Storage.getDefaultStore();
            return axios.get(serverApi+`orders/${store.StoreDB}/getOrderItems/${orderID}`)
                .then(({data})=>data).catch(err=>errorHandler.tomcatError(err));
        },

        markOrderAsCollected : async (context , id)=>{
            let store = await Storage.getDefaultStore();
            let user = await Storage.getCurrentUser();
            user = user.Username;
            return axios.put(serverApi+`orders/${store.StoreDB}/markOrderAsCollected`,{ id , user })
                .then(()=> context.dispatch(`checkReadyOrders`))
                .catch(err=>errorHandler.tomcatError(err));
        },
    }
}
